import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Link,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineIcon from '@mui/icons-material/CheckBoxOutlineBlank';

import CoverdellForm, {
  COVERDELL_SCHEMA,
} from '../form/newAccount/CoverdellForm';
import CoverdellAgeWarning from '../form/newAccount/CoverdellAgeWarning';
import StepButtonBar from '../steps/StepButtonBar';
import { Account, AccountType } from '../../api/AccountApi.d';
import { AccountOwner } from '../../api/AccountOwnerApi.d';

interface EsaResponsibleIndividualInfoProps {
  account: Account;
  accountOwner: AccountOwner;
  updateAccountDetails: Function;
}

function EsaResponsibleIndividualInfo(
  props: EsaResponsibleIndividualInfoProps
) {
  const [isEditing, setIsEditing] = useState(false as boolean);
  const { account, accountOwner, updateAccountDetails } = props;
  const {
    accountType,
    responsibleFirstName,
    responsibleLastName,
    responsibleEmailAddress,
    responsiblePhoneNumber,
    responsibleAddressLine1,
    responsibleAddressLine2,
    responsibleCity,
    responsibleState,
    responsibleZip,
    responsibleCountry,
    responsibleAfterMajority,
    allowBeneficiaryChange,
  } = account;

  if (accountType !== AccountType.esa) {
    return null;
  }

  return (
    <Accordion sx={{ p: 1 }} elevation={0}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="body2">Responsible Individual</Typography>
      </AccordionSummary>

      <AccordionDetails>
        {isEditing ? (
          <Formik
            initialValues={{ ...account }}
            onSubmit={async (data) => {
              await updateAccountDetails(data);
              setIsEditing(false);
            }}
            onReset={() => {}}
            validationSchema={COVERDELL_SCHEMA}
          >
            {({ isSubmitting }) => {
              return (
                <Form>
                  <Grid container spacing={3}>
                    <CoverdellForm
                      accountOwner={accountOwner}
                      account={account}
                    />
                    <CoverdellAgeWarning accountOwner={accountOwner} />
                  </Grid>
                  <StepButtonBar
                    isSubmitting={isSubmitting}
                    onCancel={() => {
                      setIsEditing(false);
                    }}
                  />
                </Form>
              );
            }}
          </Formik>
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Typography variant="h6">First Name</Typography>
              <Typography variant="body1">{responsibleFirstName}</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h6">Last Name</Typography>
              <Typography variant="body1">{responsibleLastName}</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h6">Phone</Typography>
              <Link color="inherit" href={`tel:${responsiblePhoneNumber}`}>
                <Typography gutterBottom>{responsiblePhoneNumber}</Typography>
              </Link>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h6">Email</Typography>
              <Link color="inherit" href={`mailto:${responsibleEmailAddress}`}>
                <Typography gutterBottom>{responsibleEmailAddress}</Typography>
              </Link>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Address</Typography>
              <Typography variant="body1">{responsibleAddressLine1}</Typography>
              <Typography variant="body1">{responsibleAddressLine2}</Typography>
              <Typography variant="body1">
                {responsibleCity}, {responsibleState} {responsibleZip}
              </Typography>
              <Typography variant="body1">
                {responsibleCountry}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={1} wrap="nowrap" alignItems="center">
                <Grid item>
                  {allowBeneficiaryChange ? (
                    <CheckBoxIcon />
                  ) : (
                    <CheckBoxOutlineIcon />
                  )}
                </Grid>
                <Grid item>
                  <Typography variant="body1">
                    May change the designated beneficiary to another member of
                    the individual’s family.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} maxWidth="sm">
              <Grid container spacing={1} wrap="nowrap" alignItems="center">
                <Grid item>
                  {responsibleAfterMajority ? (
                    <CheckBoxIcon />
                  ) : (
                    <CheckBoxOutlineIcon />
                  )}
                </Grid>
                <Grid item>
                  <Typography variant="body1">
                    Will continue to serve as the responsible individual after
                    the designated beneficiary attains the age of majority under
                    state law
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Button
                aria-label="Edit responsible individual"
                variant="contained"
                color="primary"
                startIcon={<EditIcon />}
                onClick={() => {
                  setIsEditing(true);
                }}
              >
                Edit Details
              </Button>
            </Grid>
          </Grid>
        )}
      </AccordionDetails>
    </Accordion>
  );
}

export default EsaResponsibleIndividualInfo;
