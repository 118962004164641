import React from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { ErrorBoundary } from 'react-error-boundary';

import demoTheme from './themes/demoTheme';
import { GlobalContextProvider } from './auth/GlobalContext';
import ProtectedRoute from './auth/ProtectedRoute';
import GlobalMessages from './components/GlobalMessages';
import { UserRole } from './api/UserApi.d';

import AccountOwnerProfile from './page/AccountOwnerProfile';
import AccountSearch from './page/AccountSearch';
import OrganizationSearch from './page/OrganizationSearch';
import OrganizationProfile from './page/OrganizationProfile';
import AddNewOrg from './page/AddNewOrg';
import AddNewAccount from './page/AddNewAccount';
import DesignateBeneficiaries from './page/DesignateBeneficiaries';
import Dashboard from './page/Dashboard';
import Transactions from './page/Transactions';
import MakeContribution from './page/MakeContribution';
import MakeDistribution from './page/MakeDistribution';
import NewRecurringDistribution from './page/NewRecurringDistribution';
import MakeRolloverTransfer from './page/MakeRolloverTransfer';
import UserSearch from './page/UserSearch';
import AddNewUser from './page/AddNewUser';
import AddMultipleUsers from './page/AddMultipleUsers';
import UserProfile from './page/UserProfile';
import { HubSpotLiveChat } from './components/third-party/HubSpotLiveChat';
import InheritedIra from './page/InheritedIra';
import BeneficiaryClaims from './page/BeneficiaryClaims';
import TaxReporting from './page/TaxReporting';
import RecurringDistributions from './page/RecurringDistributions';
import RecurringDistributionsSettings from './page/RecurringDistributionsSettings';
import SiteSettings from './page/SiteSettings';
import AddMultipleAccounts from './page/AddMultipleAccounts';
import DataImport from './page/DataImport';
import UpdateAccountOwners from './page/UpdateAccountOwners';
import SiraPageError from './components/SiraPageError';
import RmdNotificationList from './components/recurringDistributions/RmdNotificationList';
import WithholdingNotificationsList from './components/recurringDistributions/WithholdingNotificationsList';
import ScheduledDistributionList from './components/recurringDistributions/ScheduledDistributionList';
import Reports from './page/Reports';
import ReportsDeposit from './page/ReportsDeposit';
import ReportsDistribution from './page/ReportsDistribution';
import AddContributions from './page/AddContributions';
import SubmitBeneficiaryClaim from './components/form/beneficiariesClaims/SubmitBeneficiaryClaim';
import ViewBeneficiaryClaim from './components/form/beneficiariesClaims/ViewBeneficiaryClaim';
import BeneficiaryClaimProfile from './page/BeneficiaryClaimProfile';
import AddDistributions from './page/AddDistributions';
import ReportsAccountsSummary from './page/ReportsAccountsSummary';
import ReportsFmv from './page/ReportsFmv';
import ReportsRmdSummary from './page/ReportsRmdSummary';
import BeneficiaryClaimsDashboard from './page/BeneficiaryClaimsDashboard';
import AddRecurringDistributions from './page/AddRecurringDistributions';
import EditUserProfile from './components/userProfile/EditUserProfile';
import ExternalAccountOwnerProfile from './page/ExternalAccountOwnerProfile';
import TaxReport from './components/form/tax/TaxReport';
import TaxReportFmv from './components/form/tax/TaxReportingFMV';
import TaxFormWorkFlow from './components/form/tax/TaxFormWorkFlow';
import ReportsRmdSchedule from './page/ReportsRmdSchedule';
import TaxReport5498 from './components/form/tax/TaxReport5498';
import TaxReportingChecklist from './page/TaxReportingChecklist';
import Add5498TaxReports from './page/Add5498TaxReports';
import AddFairMarketValue from './page/AddFairMarketValue';
import AddTaxReports from './page/AddTaxReports';
import TaxReporting5498Checklist from './page/TaxReporting5498CheckList';
import Total1099Page from './components/form/tax/Tax1099TotalPages';
import ScrollToTop from './ScrollToTop';
import TaxReportingDashboard from './page/TaxReportingDashboard';
import OwnerNewAccount from './page/OwnerNewAccount';
import OwnerDesignatedBeneficiaries from './page/owner/OwnerDesignatedBeneficiaries';
import OwnerContribution from './page/owner/OwnerContribution';
import OwnerDistribution from './page/owner/OwnerDistribution';
import OwnerRolloverTransfer from './page/owner/OwnerRolloverTransfer';
import OwnerRecurringDistribution from './page/owner/OwnerRecurringDistribution';
import AuditReports from './page/AuditReports';
import ReportsPendingTransaction from './page/ReportsPendingTransaction';
import StateTaxFiles1099 from './components/form/tax/StateTaxFiles1099';
import DownloadTaxForm from './page/DownloadTaxForm';
import ErrorPage from './ErrorPage';
import { SessionTimeoutProvider } from './utils/SessionTimeout';
import SessionTimeoutModal from './utils/SessionTimeoutModal';

const auth0Config = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
};

const allAccess = [
  UserRole.orgBeneficiaryClaims,
  UserRole.orgDataProcessor,
  UserRole.orgRecurringDistributions,
  UserRole.orgSiteAdmin,
  UserRole.orgTaxReporting,
  UserRole.orgTransactionsAdmin,
  UserRole.orgTransactions,
  UserRole.orgUserAdmin,
];

function App() {
  return (
    <Auth0Provider
      {...auth0Config}
      authorizationParams={{
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        redirect_uri: window.location.origin,
      }}
    >
        <StyledEngineProvider injectFirst>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <GlobalContextProvider>
              <ThemeProvider theme={demoTheme}>
                <BrowserRouter>
                  <GlobalMessages />
                  <SessionTimeoutModal />
                  <HubSpotLiveChat />
                  {/* Add a page-level error here */}
                  <ErrorBoundary FallbackComponent={SiraPageError}>
                    <ScrollToTop />
                    <Routes>
                      <Route path="/error" element={<ErrorPage />} />
                      {/* TRANSACTIONS */}
                      <Route
                        path="/transactions"
                        element={
                          <ProtectedRoute
                            roles={[
                              UserRole.orgTransactions,
                              UserRole.orgTransactionsAdmin,
                            ]}
                            component={Transactions}
                            location=""
                            navigator={undefined}
                          />
                        }
                      />
                      <Route
                        path="/transactions/newAccount"
                        element={
                          <ProtectedRoute
                            roles={[
                              UserRole.orgTransactions,
                              UserRole.orgTransactionsAdmin,
                            ]}
                            location=""
                            navigator={undefined}
                            component={AddNewAccount}
                          />
                        }
                      />
                      <Route
                        path="/owner/newAccount"
                        element={
                          <ProtectedRoute
                            roles={[UserRole.accountOwner]}
                            location=""
                            navigator={undefined}
                            component={OwnerNewAccount}
                          />
                        }
                      />
                      <Route
                        path="/owner/"
                        element={
                          <ProtectedRoute
                            roles={[UserRole.accountOwner]}
                            location=""
                            navigator={undefined}
                            component={AccountOwnerProfile}
                          />
                        }
                      />
                      {/* owner transaction */}
                      <Route
                        path="/owner/beneficiaries"
                        element={
                          <ProtectedRoute
                            roles={[UserRole.accountOwner]}
                            location=""
                            navigator={undefined}
                            component={OwnerDesignatedBeneficiaries}
                          />
                        }
                      />
                      <Route
                        path="/owner/contributions"
                        element={
                          <ProtectedRoute
                            roles={[UserRole.accountOwner]}
                            location=""
                            navigator={undefined}
                            component={OwnerContribution}
                          />
                        }
                      />
                      <Route
                        path="/owner/distributions"
                        element={
                          <ProtectedRoute
                            roles={[UserRole.accountOwner]}
                            location=""
                            navigator={undefined}
                            component={OwnerDistribution}
                          />
                        }
                      />
                      <Route
                        path="/owner/recurringDistributions"
                        element={
                          <ProtectedRoute
                            roles={[UserRole.accountOwner]}
                            location=""
                            navigator={undefined}
                            component={OwnerRecurringDistribution}
                          />
                        }
                      />
                      <Route
                        path="/owner/rolloverTransfer"
                        element={
                          <ProtectedRoute
                            roles={[UserRole.accountOwner]}
                            location=""
                            navigator={undefined}
                            component={OwnerRolloverTransfer}
                          />
                        }
                      />
                      {/* branch transaction */}
                      <Route
                        path="/transactions/beneficiaries"
                        element={
                          <ProtectedRoute
                            roles={[
                              UserRole.orgTransactions,
                              UserRole.orgTransactionsAdmin,
                            ]}
                            location=""
                            navigator={undefined}
                            component={DesignateBeneficiaries}
                          />
                        }
                      />
                      <Route
                        path="/transactions/contributions"
                        element={
                          <ProtectedRoute
                            roles={[
                              UserRole.orgTransactions,
                              UserRole.orgTransactionsAdmin,
                            ]}
                            location=""
                            navigator={undefined}
                            component={MakeContribution}
                          />
                        }
                      />
                      <Route
                        path="/transactions/distributions"
                        element={
                          <ProtectedRoute
                            roles={[
                              UserRole.orgTransactions,
                              UserRole.orgTransactionsAdmin,
                            ]}
                            location=""
                            navigator={undefined}
                            component={MakeDistribution}
                          />
                        }
                      />
                      <Route
                        path="/transactions/recurringDistributions"
                        element={
                          <ProtectedRoute
                            roles={[
                              UserRole.orgTransactions,
                              UserRole.orgTransactionsAdmin,
                            ]}
                            location=""
                            navigator={undefined}
                            component={NewRecurringDistribution}
                          />
                        }
                      />
                      <Route
                        path="/transactions/rolloverTransfer"
                        element={
                          <ProtectedRoute
                            roles={[
                              UserRole.orgTransactions,
                              UserRole.orgTransactionsAdmin,
                            ]}
                            location=""
                            navigator={undefined}
                            component={MakeRolloverTransfer}
                          />
                        }
                      />
                      <Route
                        path="/transactions/inheritIra"
                        element={
                          <ProtectedRoute
                            roles={[
                              UserRole.orgTransactions,
                              UserRole.orgTransactionsAdmin,
                            ]}
                            location=""
                            navigator={undefined}
                            component={InheritedIra}
                          />
                        }
                      />
                      {/* USERS */}
                      <Route
                        path="/users"
                        element={
                          <ProtectedRoute
                            roles={[UserRole.orgUserAdmin]}
                            location=""
                            navigator={undefined}
                            component={UserSearch}
                          />
                        }
                      />
                      <Route
                        path="/users/addUser"
                        element={
                          <ProtectedRoute
                            roles={[UserRole.orgUserAdmin]}
                            location=""
                            navigator={undefined}
                            component={AddNewUser}
                          />
                        }
                      />
                      <Route
                        path="/users/:userId"
                        element={
                          <ProtectedRoute
                            roles={[UserRole.orgUserAdmin]}
                            location=""
                            navigator={undefined}
                            component={UserProfile}
                          />
                        }
                      />
                      <Route
                        path="/editProfile/"
                        element={
                          <ProtectedRoute
                            location=""
                            navigator={undefined}
                            component={EditUserProfile}
                          />
                        }
                      />
                      {/* ORGANIZATION */}
                      <Route
                        path="/onboarding"
                        element={
                          <ProtectedRoute
                            roles={[UserRole.admin]}
                            location=""
                            navigator={undefined}
                            component={AddNewOrg}
                          />
                        }
                      />
                      <Route
                        path="/org"
                        element={
                          <ProtectedRoute
                            roles={[UserRole.multi, UserRole.orgSiteAdmin]}
                            location=""
                            navigator={undefined}
                            component={OrganizationSearch}
                          />
                        }
                      />
                      <Route
                        path="/orgSettings"
                        element={
                          <ProtectedRoute
                            roles={[UserRole.orgSiteAdmin]}
                            location=""
                            navigator={undefined}
                            component={OrganizationProfile}
                          />
                        }
                      />
                      {/* DATA IMPORT */}
                      <Route
                        path="/dataImport"
                        element={
                          <ProtectedRoute
                            roles={[UserRole.orgDataProcessor]}
                            location=""
                            navigator={undefined}
                            component={DataImport}
                          />
                        }
                      />
                      <Route
                        path="/dataImport/addUsers"
                        element={
                          <ProtectedRoute
                            roles={[UserRole.orgDataProcessor]}
                            location=""
                            navigator={undefined}
                            component={AddMultipleUsers}
                          />
                        }
                      />
                      <Route
                        path="/dataImport/newAccounts"
                        element={
                          <ProtectedRoute
                            roles={[UserRole.orgDataProcessor]}
                            location=""
                            navigator={undefined}
                            component={AddMultipleAccounts}
                          />
                        }
                      />
                      <Route
                        path="/dataImport/distributions"
                        element={
                          <ProtectedRoute
                            roles={[UserRole.orgDataProcessor]}
                            location=""
                            navigator={undefined}
                            component={AddDistributions}
                          />
                        }
                      />{' '}
                      <Route
                        path="/taxReporting/checklist/dataImport/distributions"
                        element={
                          <ProtectedRoute
                            roles={[UserRole.orgDataProcessor]}
                            location=""
                            navigator={undefined}
                            component={AddDistributions}
                          />
                        }
                      />{' '}
                      <Route
                        path="/taxReporting/totalsPage/1099"
                        element={
                          <ProtectedRoute
                            roles={[UserRole.orgDataProcessor]}
                            location=""
                            navigator={undefined}
                            component={Total1099Page}
                          />
                        }
                      />{' '}
                      <Route
                        path="/taxReporting/stateTaxFiles/1099"
                        element={
                          <ProtectedRoute
                            roles={[UserRole.orgDataProcessor]}
                            location=""
                            navigator={undefined}
                            component={StateTaxFiles1099}
                          />
                        }
                      />
                      <Route
                        path="/dataImport/recurringDistributions"
                        element={
                          <ProtectedRoute
                            roles={[UserRole.orgDataProcessor]}
                            location=""
                            navigator={undefined}
                            component={AddRecurringDistributions}
                          />
                        }
                      />{' '}
                      <Route
                        path="/dataImport/taxReporting"
                        element={
                          <ProtectedRoute
                            roles={[UserRole.orgDataProcessor]}
                            location=""
                            navigator={undefined}
                            component={AddTaxReports}
                          />
                        }
                      />{' '}
                      <Route
                        path="/taxReporting/checklist5498/taxReporting5498"
                        element={
                          <ProtectedRoute
                            roles={[UserRole.orgDataProcessor]}
                            location=""
                            navigator={undefined}
                            component={Add5498TaxReports}
                          />
                        }
                      />{' '}
                      <Route
                        path="/taxReporting/checklist/taxReporting1099"
                        element={
                          <ProtectedRoute
                            roles={[UserRole.orgDataProcessor]}
                            location=""
                            navigator={undefined}
                            component={AddTaxReports}
                          />
                        }
                      />{' '}
                      <Route
                        path="/dataImport/taxReporting5498"
                        element={
                          <ProtectedRoute
                            roles={[UserRole.orgDataProcessor]}
                            location=""
                            navigator={undefined}
                            component={Add5498TaxReports}
                          />
                        }
                      />
                      <Route
                        path="/dataImport/deposits"
                        element={
                          <ProtectedRoute
                            roles={[UserRole.orgDataProcessor]}
                            location=""
                            navigator={undefined}
                            component={AddContributions}
                          />
                        }
                      />{' '}
                      <Route
                        path="/taxReporting/checklist5498/deposits"
                        element={
                          <ProtectedRoute
                            roles={[UserRole.orgDataProcessor]}
                            location=""
                            navigator={undefined}
                            component={AddContributions}
                          />
                        }
                      />
                      <Route
                        path="/dataImport/updateAccountOwners"
                        element={
                          <ProtectedRoute
                            roles={[UserRole.orgDataProcessor]}
                            location=""
                            navigator={undefined}
                            component={UpdateAccountOwners}
                          />
                        }
                      />{' '}
                      <Route
                        path="/dataImport/fairMarketValue"
                        element={
                          <ProtectedRoute
                            roles={[UserRole.orgDataProcessor]}
                            location=""
                            navigator={undefined}
                            component={AddFairMarketValue}
                          />
                        }
                      />{' '}
                      <Route
                        path="/taxReporting/checklist5498/fairMarketValue"
                        element={
                          <ProtectedRoute
                            roles={[UserRole.orgDataProcessor]}
                            location=""
                            navigator={undefined}
                            component={AddFairMarketValue}
                          />
                        }
                      />
                      {/* ACCOUNT OWNER */}
                      <Route
                        path="/accountOwner"
                        element={
                          <ProtectedRoute
                            roles={[
                              UserRole.orgTransactions,
                              UserRole.orgTransactionsAdmin,
                            ]}
                            location=""
                            navigator={undefined}
                            component={AccountSearch}
                          />
                        }
                      />
                      <Route
                        path="/accountOwner/:accountOwnerId/account/:accountId"
                        element={
                          <ProtectedRoute
                            roles={[
                              UserRole.orgTransactions,
                              UserRole.orgTransactionsAdmin,
                            ]}
                            location=""
                            navigator={undefined}
                            component={AccountOwnerProfile}
                          />
                        }
                      />
                      <Route
                        path="/accountOwner/:accountOwnerId"
                        element={
                          <ProtectedRoute
                            roles={[
                              UserRole.orgTransactions,
                              UserRole.orgTransactionsAdmin,
                            ]}
                            location=""
                            navigator={undefined}
                            component={AccountOwnerProfile}
                          />
                        }
                      />{' '}
                      <Route
                        path="/accountOwner/:accountOwnerId/externalAccountOwner/"
                        element={
                          <ProtectedRoute
                            roles={[
                              UserRole.orgTransactions,
                              UserRole.orgTransactionsAdmin,
                            ]}
                            component={ExternalAccountOwnerProfile}
                            location=""
                            navigator={undefined}
                          />
                        }
                      />
                      {/* RECUR DISTRIBUTIONS */}
                      <Route
                        path="/recurringDistributions"
                        element={
                          <ProtectedRoute
                            roles={[UserRole.orgRecurringDistributions]}
                            component={RecurringDistributions}
                            location=""
                            navigator={undefined}
                          />
                        }
                      />
                      <Route
                        path="/recurringDistributions/settings"
                        element={
                          <ProtectedRoute
                            roles={[UserRole.orgRecurringDistributions]}
                            component={RecurringDistributionsSettings}
                            location=""
                            navigator={undefined}
                          />
                        }
                      />
                      <Route
                        path="/recurringDistributions/rmdNotification"
                        element={
                          <ProtectedRoute
                            roles={[UserRole.orgRecurringDistributions]}
                            component={RmdNotificationList}
                            location=""
                            navigator={undefined}
                          />
                        }
                      />
                      <Route
                        path="/recurringDistributions/withholdingNotifications"
                        element={
                          <ProtectedRoute
                            roles={[UserRole.orgRecurringDistributions]}
                            component={WithholdingNotificationsList}
                            location=""
                            navigator={undefined}
                          />
                        }
                      />
                      <Route
                        path="/recurringDistributions/scheduledDistributionList"
                        element={
                          <ProtectedRoute
                            roles={[UserRole.orgRecurringDistributions]}
                            component={ScheduledDistributionList}
                            location=""
                            navigator={undefined}
                          />
                        }
                      />
                      <Route
                        path="/recurringDistributions/rmdSummary"
                        element={
                          <ProtectedRoute
                            roles={allAccess}
                            component={ReportsRmdSummary}
                            location=""
                            navigator={undefined}
                          />
                        }
                      />
                      <Route
                        path="/recurringDistributions/schedules"
                        element={
                          <ProtectedRoute
                            roles={allAccess}
                            component={ReportsRmdSchedule}
                            location=""
                            navigator={undefined}
                          />
                        }
                      />
                      {/* BENEFICIARY CLAIMS */}
                      <Route
                        path="/beneficiaryClaims"
                        element={
                          <ProtectedRoute
                            roles={[UserRole.orgBeneficiaryClaims]}
                            component={BeneficiaryClaims}
                            location=""
                            navigator={undefined}
                          />
                        }
                      />
                      <Route
                        path="/beneficiaryClaimsDashboard"
                        element={
                          <ProtectedRoute
                            roles={[UserRole.multi]}
                            component={BeneficiaryClaimsDashboard}
                            location=""
                            navigator={undefined}
                          />
                        }
                      />
                      <Route
                        path="/taxReportingDashboard"
                        element={
                          <ProtectedRoute
                            roles={[UserRole.multi]}
                            component={TaxReportingDashboard}
                            location=""
                            navigator={undefined}
                          />
                        }
                      />
                      <Route
                        path="/beneficiaryClaims/viewBeneficiaryClaim/beneficiaryClaimsProfile"
                        element={
                          <ProtectedRoute
                            roles={[UserRole.orgBeneficiaryClaims]}
                            component={BeneficiaryClaimProfile}
                            location=""
                            navigator={undefined}
                          />
                        }
                      />
                      <Route
                        path="/beneficiaryClaims/submitBeneficiaryClaim"
                        element={
                          <ProtectedRoute
                            roles={[UserRole.orgBeneficiaryClaims]}
                            component={SubmitBeneficiaryClaim}
                            location=""
                            navigator={undefined}
                          />
                        }
                      />
                      <Route
                        path="/beneficiaryClaims/viewBeneficiaryClaim"
                        element={
                          <ProtectedRoute
                            roles={[UserRole.orgBeneficiaryClaims]}
                            component={ViewBeneficiaryClaim}
                            location=""
                            navigator={undefined}
                          />
                        }
                      />
                      {/* TAX REPORTING */}
                      <Route
                        path="/taxReporting"
                        element={
                          <ProtectedRoute
                            roles={[UserRole.orgTaxReporting]}
                            component={TaxReporting}
                            location=""
                            navigator={undefined}
                          />
                        }
                      />
                      <Route
                        path="/taxReporting/checklist"
                        element={
                          <ProtectedRoute
                            roles={[UserRole.orgTaxReporting]}
                            component={TaxReportingChecklist}
                            location=""
                            navigator={undefined}
                          />
                        }
                      />{' '}
                      <Route
                        path="/taxReporting/checklist5498"
                        element={
                          <ProtectedRoute
                            roles={[UserRole.orgTaxReporting]}
                            component={TaxReporting5498Checklist}
                            location=""
                            navigator={undefined}
                          />
                        }
                      />
                      <Route
                        path="/accountOwner/:accountOwnerId/account/:accountId/taxFormWorkflow"
                        element={
                          <ProtectedRoute
                            roles={[UserRole.orgTaxReporting]}
                            component={TaxFormWorkFlow}
                            location=""
                            navigator={undefined}
                          />
                        }
                      />
                      <Route
                        path="/taxReporting/FMV"
                        element={
                          <ProtectedRoute
                            roles={[UserRole.orgTaxReporting]}
                            component={TaxReportFmv}
                            location=""
                            navigator={undefined}
                          />
                        }
                      />
                      <Route
                        path="/taxReporting/1099"
                        element={
                          <ProtectedRoute
                            roles={[UserRole.orgTaxReporting]}
                            component={TaxReport}
                            location=""
                            navigator={undefined}
                          />
                        }
                      />
                      <Route
                        path="/taxReporting/5498"
                        element={
                          <ProtectedRoute
                            roles={[UserRole.orgTaxReporting]}
                            component={TaxReport5498}
                            location=""
                            navigator={undefined}
                          />
                        }
                      />{' '}
                      <Route
                        path="/taxReporting/downloadTaxForms"
                        element={
                          <ProtectedRoute
                            roles={[UserRole.orgTaxReporting]}
                            component={DownloadTaxForm}
                            location=""
                            navigator={undefined}
                          />
                        }
                      />{' '}
                      <Route
                        path="/taxReporting/checklist/1099TaxReport"
                        element={
                          <ProtectedRoute
                            roles={[UserRole.orgTaxReporting]}
                            component={TaxReport}
                            location=""
                            navigator={undefined}
                          />
                        }
                      />
                      <Route
                        path="/taxReporting/checklist5498/5498TaxReport"
                        element={
                          <ProtectedRoute
                            roles={[UserRole.orgTaxReporting]}
                            component={TaxReport5498}
                            location=""
                            navigator={undefined}
                          />
                        }
                      />
                      <Route
                        path="/taxFormWorkflow"
                        element={
                          <ProtectedRoute
                            roles={[UserRole.orgTaxReporting]}
                            component={TaxFormWorkFlow}
                            location=""
                            navigator={undefined}
                          />
                        }
                      />
                      {/* SETTINGS */}
                      <Route
                        path="/siteSettings"
                        element={
                          <ProtectedRoute
                            roles={[UserRole.orgSiteAdmin]}
                            component={SiteSettings}
                            location=""
                            navigator={undefined}
                          />
                        }
                      />
                      {/* REPORTS */}
                      <Route
                        path="/reports"
                        element={
                          <ProtectedRoute
                            roles={allAccess}
                            component={Reports}
                            location=""
                            navigator={undefined}
                          />
                        }
                      />
                      <Route
                        path="/reports/auditReports"
                        element={
                          <ProtectedRoute
                            roles={allAccess}
                            component={AuditReports}
                            location=""
                            navigator={undefined}
                          />
                        }
                      />
                      <Route
                        path="/reports/deposits"
                        element={
                          <ProtectedRoute
                            roles={allAccess}
                            component={ReportsDeposit}
                            location=""
                            navigator={undefined}
                          />
                        }
                      />{' '}
                      <Route
                        path="/taxReporting/checklist5498/deposits"
                        element={
                          <ProtectedRoute
                            roles={allAccess}
                            component={ReportsDeposit}
                            location=""
                            navigator={undefined}
                          />
                        }
                      />
                      <Route
                        path="/reports/distributions"
                        element={
                          <ProtectedRoute
                            roles={allAccess}
                            component={ReportsDistribution}
                            location=""
                            navigator={undefined}
                          />
                        }
                      />
                      <Route
                        path="/reports/accountsSummary"
                        element={
                          <ProtectedRoute
                            roles={allAccess}
                            component={ReportsAccountsSummary}
                            location=""
                            navigator={undefined}
                          />
                        }
                      />
                      <Route
                        path="/taxReporting/checklist5498/fmv"
                        element={
                          <ProtectedRoute
                            roles={allAccess}
                            component={ReportsFmv}
                            location=""
                            navigator={undefined}
                          />
                        }
                      />{' '}
                      <Route
                        path="/reports/fmv"
                        element={
                          <ProtectedRoute
                            roles={allAccess}
                            component={ReportsFmv}
                            location=""
                            navigator={undefined}
                          />
                        }
                      />{' '}
                      <Route
                        path="/reports/pendingTransactions"
                        element={
                          <ProtectedRoute
                            roles={allAccess}
                            component={ReportsPendingTransaction}
                            location=""
                            navigator={undefined}
                          />
                        }
                      />
                      {/* This one last as default so invalid routes load the dashboard */}
                      <Route
                        path="/"
                        element={
                          <ProtectedRoute
                            component={Dashboard}
                            location=""
                            navigator={undefined}
                          />
                        }
                      />
                    </Routes>
                  </ErrorBoundary>
                </BrowserRouter>
              </ThemeProvider>
            </GlobalContextProvider>
          </LocalizationProvider>
        </StyledEngineProvider>
    </Auth0Provider>
  );
}

export default App;
