// src/ErrorPage.tsx
import React from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Container, Typography, Button } from '@mui/material';


const ErrorPage: React.FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const errorValue = queryParams.get('error');
  const errorDescription = queryParams.get('error_description');

  console.log('ErrorPage', errorValue, errorDescription);

  return (
    <Box
      sx={{
        backgroundColor: 'rgb(12, 31, 42)',
        position: 'absolute',
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Container
        sx={{
          backgroundColor: 'white',
          border: '1px solid #ccc',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          height: '300px                                                       ',
          maxWidth: 960,
          padding: 2,
        }}
      >
        <Box sx={{ textAlign: 'center', maxWidth: 600, margin: 'auto' }}>
          <Typography variant="h1" color="error">
            &#9888;
          </Typography>
          <Typography variant="h3">Oops! {errorValue}</Typography>
          <Typography variant="body1">{errorDescription}</Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            gap: 2,
            marginTop: 2,
          }}
        >
          <Button
            LinkComponent={'a'}
            href="https://login.app.superiorira.com"
            variant="contained"
            color="primary"
            sx={{ padding: '8px 16px' }}
          >
            Try Again
          </Button>

        </Box>
      </Container>
    </Box>
  );
};

export default ErrorPage;