import React, { useEffect, useState } from 'react';
import {
  useTheme,
  Box,
  Paper,
  Typography,
  Grid,
  Link,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useUser } from '../../auth/useUser';
import { Organization } from '../../api/OrganizationApi.d';
import { getUser, getUsers } from '../../api/UserApi';
import Layout from '../Layout';
import PaperlessPage from '../PaperlessPage';
import { UserRole, UserResponse, User } from '../../api/UserApi.d';
import { useGlobalContext } from '../../auth/useGlobalContext';
import UserProfileDetails from './UserProfileDetails';
import UserProfileRoleDetails from './UserProfileRoleDetails';
import { notificationPrefItems } from '../../app.constants';

export interface UserPageParams {
  userId: string;
}

function EditUserProfile() {
  let isMounted = true;
  const theme = useTheme();
  const { user } = useUser();
  const { organization = {} as Organization, addGlobalMessage } =
    useGlobalContext();
  const [response = {}, setResponse] = useState({} as UserResponse);
  const { data: selectedUser = {} as User } = response;
  const {
    firstName = '',
    lastName = '',
    phoneNumber = '',
    emailAddress = '',
    branchId: userBranchId = '',
    organizationRole = '',
    mainContact=false,
    notificationPreference,
  } = selectedUser;
  const { branches = [] } = organization;
  const { name: userBranchName } =
    branches.find(({ branchId = '' }) => branchId === userBranchId) || {};
  const [expand, setExpanded] = useState(false);
  const [expandRole, setRoleExpanded] = useState(false);
  const [editing, setEditing] = useState(true);


  const classes = {
    profileCard: {
      color: theme.palette.grey[300],
      backgroundColor: theme.palette.primary.main,
    },
  };

  const getUserInfo = (userId) => {
    getUser(user.organizationId, userId, user.token)
      .then((res) => {
        if (isMounted) {
          setResponse(res);
          setExpanded(true);
          if (user.roles.includes(UserRole.admin)) {
            setRoleExpanded(true);
          }
        }
      })
      .catch((err) => {
        if (isMounted) {
          setResponse({
            data: {},
            errorMessage: err?.response?.data?.message,
          });
        }
      });
  };

  useEffect(() => {
    if (user.token) {
      getUsers(user.organizationId, user.token)
        .then((res) => {
          const data = res.data.find((val) => {
            return val.auth0UserId === user.sub && response;
          });
          getUserInfo(data.userId.toString());
        })
        .catch(() => {
          addGlobalMessage('Error fetching user information');
        });
    }

    return () => {
      isMounted = false;
    };
  }, [user.token]);

  return (
    <Layout>
      <PaperlessPage>
        <>
          <Typography variant="overline" gutterBottom>
            Edit User Profile
          </Typography>
          <Box mt={2}>
            <Paper elevation={3} sx={classes.profileCard}>
              <Box p={4} bgcolor="primary">
                <Typography color="secondary" variant="overline">
                  Name
                </Typography>

                <Typography color="secondary" variant="h3" gutterBottom>
                  {firstName} {lastName}
                </Typography>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Grid container wrap="nowrap" spacing={1}>
                      <Grid item>
                        <EmailIcon color="secondary" />
                      </Grid>
                      <Grid item>
                        <Link color="inherit" href={`mailto:${emailAddress}`}>
                          <Typography gutterBottom>{emailAddress}</Typography>
                        </Link>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Grid container wrap="nowrap" spacing={1}>
                      <Grid item>
                        <PhoneIcon color="secondary" />
                      </Grid>
                      <Grid item>
                        <Link color="inherit" href={`tel:${phoneNumber}`}>
                          <Typography gutterBottom>{phoneNumber}</Typography>
                        </Link>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography variant="subtitle2" color="secondary">
                          Title
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography>{organizationRole || '---'}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography variant="subtitle2" color="secondary">
                          Branch
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography>{userBranchName || '---'}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography variant="subtitle2" color="secondary">
                          Main Contact
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography>
                          {mainContact ? 'True' : 'False' }
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography variant="subtitle2" color="secondary">
                          Notification Preference
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography>
                          {notificationPrefItems[notificationPreference]}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Box>

          <Box mt={4}>
            <Accordion
              onChange={(e, expanded) => {
                setExpanded(expanded);
              }}
              expanded={expand}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h5" color="secondary">
                  {editing && 'Edit'} User Information
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <UserProfileDetails
                  response={response}
                  setResponse={setResponse}
                  editing
                  organization={organization}
                  setEditing={setEditing}
                />
              </AccordionDetails>
            </Accordion>

            {user.roles.includes(UserRole.orgUserAdmin) && (
              <Accordion
                onChange={(e, expanded) => {
                  setRoleExpanded(expanded);
                }}
                expanded={expandRole}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h5" color="secondary">
                    {editing && 'Edit'} Role &amp; Permissions
                  </Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <UserProfileRoleDetails
                    response={response}
                    setResponse={setResponse}
                    editing
                    setEditing={setEditing}
                  />
                </AccordionDetails>
              </Accordion>
            )}
          </Box>
        </>
      </PaperlessPage>
    </Layout>
  );
}

export default EditUserProfile;
