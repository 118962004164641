/* eslint-disable react/jsx-no-bind */
import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { Grid, Button, Box, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Alert from '@mui/material/Alert';

import { useGlobalContext } from '../../auth/useGlobalContext';
import { Account } from '../../api/AccountApi.d';
import { useStates } from '../form/useStates';
import { useUser } from '../../auth/useUser';
import SiraRadioField, { RadioGroupOption } from '../form/SiraRadioField';
import SiraTextField from '../form/SiraTextField';
import BeneficiariesList from './BeneficiariesList';
import PluralizedString from '../PluralizedString';
import { getAccountOwner } from '../../api/AccountOwnerApi';
import {
  createBeneficiaries,
  updatePendingBeneficiaries,
  changeBeneficiariesStatus,
} from '../../api/BeneficiariesApi';
import {
  Beneficiaries,
  BeneficiaryStatus,
  BeneficiaryStatusState,
} from '../../api/BeneficiariesApi.d';
import { newAccountDepositHelpTxt } from '../form/newAccount/resource.txt';
import { SpousalConsentOption } from '../../api/OrganizationApi.d';
import { UserRole } from '../../api/UserApi.d';
import { errorMessages } from '../../utils/errorhandling.utils';

interface PrimaryBeneficiariesProps {
  account: Account;
  beneficiaries: Beneficiaries;
  onSave?: Function;
  onSkipToSignature?: Function;
  updateBeneficiaries: Function;
  updateBeneficiariesStatus: Function;
  onAddClick?: Function;
  onEditClick?: Function;
  showForm: boolean;
  beneficiariesAccount?: boolean;
  signedDate?: string;
  accountOwnerProfile?: boolean;
}

const MARITAL_STATUS_INIT = {
  maritalStatus: '',
  spouseName: '',
};

export const maritalStatusOptions: Array<RadioGroupOption> = [
  { value: 'MARRIED', label: 'Married' },
  { value: 'UNMARRIED', label: 'Not Married' },
];

export const MARITAL_STATUS_SCHEMA = yup.object({
  maritalStatus: yup.string().required().label('Marital status'),
  spouseName: yup
    .string()
    .label('Spouse Name')
    .when('maritalStatus', (maritalStatus, schema) =>
      maritalStatus === 'MARRIED' ? schema.required() : schema
    ),
});

function PrimaryBeneficiaries(props: PrimaryBeneficiariesProps) {
  let isMounted = true;
  const { user } = useUser();
  const { roles = [] } = user;
  const { addGlobalMessage, organization, customerPortalUser } = useGlobalContext();
  const { states = [] } = useStates();
  const [isLoading, setIsLoading] = useState(false);
  const [isCommunityPropertyState, setIsCommunityPropertyState] =
    useState(false);
  const [spousalConsent, setSpousalConsent] = useState(false);
  const {
    account = {},
    beneficiaries = {} as Beneficiaries,
    onSave = () => {},
    onSkipToSignature = () => {},
    onAddClick = () => {},
    onEditClick = () => {},
    updateBeneficiaries = () => {},
    updateBeneficiariesStatus = () => {},
    showForm = false,
    beneficiariesAccount = false,
    signedDate,
    accountOwnerProfile
  } = props;

  const { accountId = '' } = account;
  const { primaryBeneficiaries = [], beneficiaryStatus } = beneficiaries;
  const isAwaitingSignature = beneficiaryStatus === BeneficiaryStatus.signature;
  const isPendingReview = beneficiaryStatus === BeneficiaryStatus.review || beneficiaryStatus === BeneficiaryStatus.submitOwner;
  const { spousalConsentRequired } = organization;
const [allowAddSave,setAllowAddSave] = useState(false);
  /*
   ** Require marital status question if naming non-spouse primary
   ** beneficiaries when the account owner lives in a community
   ** property state. If a spouse is named but for < 100% there's
   ** no need to ask for the marital status or spouse name again
   */
  const requireMaritalStatus =
    Boolean(primaryBeneficiaries.length) &&
    isCommunityPropertyState &&
    !beneficiariesAccount &&
    primaryBeneficiaries.every(
      ({ relationship = '' }) => relationship !== 'SPOUSE'
    );

  const requireSpousalConsent =
    Boolean(primaryBeneficiaries.length) &&
    isCommunityPropertyState &&
    primaryBeneficiaries.some(
      ({ relationship = '', percent = 0 }) =>
        relationship === 'SPOUSE' && percent < 100
    );

  // Back to PENDING to make changes to a immutable set
  async function resetBeneficiariesToPending(): Promise<void> {
    const { version } = beneficiaries;

    await changeBeneficiariesStatus(
      user.organizationId,
      accountId,
      account.accountOwnerId,
      version.toString(),
      BeneficiaryStatusState.previous,
      {},
      user.token,
      user
    )
      .then((res) => {
        const { beneficiaryStatus: newBeneficiaryStatus } = res.data;

        if (isMounted) {
          updateBeneficiariesStatus(newBeneficiaryStatus);
        }
      })
      .catch(() => {
        addGlobalMessage("Error resetting beneficiaries' status");
      });
  }

  const savePendingBeneficiaries = async (data): Promise<void> => {
    const { spouseName } = data;
    const apiCall =
      !beneficiaryStatus || beneficiaryStatus !== BeneficiaryStatus.pending
        ? createBeneficiaries
        : updatePendingBeneficiaries;
    const mergedBeneficiary = {
      ...beneficiaries,
      requireSpousalConsent: spousalConsent,
      spouseName,
    };

    setIsLoading(true);

    await apiCall(
      mergedBeneficiary,
      user.organizationId,
      accountId,
      account.accountOwnerId,
      user.token,
      user
    )
      .then((res) => {
        if (isMounted) {
          onSave(res.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        if (isMounted) {
  
          setIsLoading(false);
          addGlobalMessage(errorMessages(err) || 'Error saving primary beneficiaries'
          );
        }
      });
  };

  // Get account owner's address state and determine if the marital property UI applies
  async function determineCommunityPropertyState() {
    const { accountOwnerId = '' } = account;

    await getAccountOwner(accountOwnerId, user.organizationId, user.token, user)
      .then((res) => {
        const { data: accountOwner } = res;
        const accountOwnerState = states.find(
          ({ abbreviation = '' }) => abbreviation === accountOwner.state
        );

        const { communityPropertyState = false } = accountOwnerState || {};

        if (isMounted) {
          setIsCommunityPropertyState(
            spousalConsentRequired === SpousalConsentOption.all ||
              communityPropertyState
          );
        }
      })
      .catch((err) => {
        const { response: { data = {} } = {} } = err;
        if (isMounted) {
          addGlobalMessage(data.message);
        }
      });
  }

  const startOver = async (): Promise<void> => {
    await resetBeneficiariesToPending();
  };

  const skipToSignature = (): void => {
    onSkipToSignature(beneficiaries);
  };

  // parse to a number and set it as 2 decimal places
  const parsePercent = (percent: string): number => {
    const parsed = parseFloat(percent);
    return Math.round(parsed * 100) / 100;
  };
  
  const percentDesignated = primaryBeneficiaries.reduce(
    (acc, { percent = 0 }) => acc + parsePercent(percent.toString()),
    0
  );

  useEffect(() => {
    determineCommunityPropertyState();

    return () => {
      isMounted = false;
    };
  }, [states.length]);
  useEffect(() => {

    if(!accountOwnerProfile ){
      setAllowAddSave(!beneficiaryStatus || beneficiaryStatus === BeneficiaryStatus.pending);
    }else{
      setAllowAddSave(false);
    }

  }, [beneficiaryStatus, accountOwnerProfile]);

  if (isAwaitingSignature) {
    return (
      <Box mt={2} mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Alert severity="warning">
              A signature is required for the current set of beneficiaries
            </Alert>
          </Grid>
          <Grid item xs={false}>
            <Button
              variant="contained"
              color="secondary"
              onClick={skipToSignature}
            >
              Continue to Signing
            </Button>
          </Grid>
          <Grid item xs={false}>
            <Button variant="contained" color="primary" onClick={startOver}>
              Edit
            </Button>
          </Grid>
        </Grid>
      </Box>
    );
  }

  return (
    <Box mt={1} mb={2}>
      <Box mb={2}>
        <Typography variant="body1" color="primary">
          Currently {primaryBeneficiaries.length} Primary{' '}
          <PluralizedString
            noun="Beneficiary"
            quantity={primaryBeneficiaries.length}
          />
        </Typography>
        {newAccountDepositHelpTxt.primaryBeneficiary.curPrimaryBeneficiary}
      </Box>

      <BeneficiariesList
        beneficiaries={primaryBeneficiaries}
        updateBeneficiaries={updateBeneficiaries}
        showForm={showForm}
        onEditClick={onEditClick}
        signedDate={signedDate}
      />

      {requireSpousalConsent && (
        <Alert severity="error">
          Your spouse must agree to this beneficiary designation.
        </Alert>
      )}

      {isPendingReview && (
        <Box mt={2} mb={2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Alert severity="warning">
                This set of beneficiaries is awaiting administrator review
              </Alert>
            </Grid>

            {roles.includes(UserRole.orgTransactionsAdmin) && (
              <Grid item xs={false}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={skipToSignature}
                >
                  Review and Approve
                </Button>
              </Grid>
            )}
            <Grid item xs={false}>
              <Button variant="contained" color="primary" onClick={startOver}>
                Make Changes
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}

      <Formik
        initialValues={{ ...MARITAL_STATUS_INIT, ...beneficiaries }}
        onSubmit={savePendingBeneficiaries}
        validationSchema={
          requireMaritalStatus ? MARITAL_STATUS_SCHEMA : yup.object()
        }
      >
        {({ values, setFieldValue }) => {
          useEffect(() => {
            setSpousalConsent(
              requireSpousalConsent || values.maritalStatus === 'MARRIED'
            );
            if (values.maritalStatus === 'UNMARRIED') {
              setFieldValue('spouseName', '');
            }
          }, [values.maritalStatus, requireSpousalConsent]);
          return (
            <Form>
              {requireMaritalStatus && (
                <Box border="1">
                  <Grid container>
                    <Grid item xs={12}>
                      {percentDesignated === 100 && (
                        <Grid item xs={12}>
                          <Field
                            label="Account Owner’s Marital Status"
                            name="maritalStatus"
                            options={maritalStatusOptions}
                            component={SiraRadioField}
                          />
                        </Grid>
                      )}
                      {values.maritalStatus === 'MARRIED' && (
                        <Grid item xs={12}>
                          <Grid item xs={12} md={5}>
                            <Box pt={1} pb={1}>
                              <SiraTextField
                                name="spouseName"
                                label="Spouse's Name"
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={12}>
                            <Alert severity="error">
                              Your spouse must agree to this beneficiary
                              designation.
                            </Alert>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              )}

              {allowAddSave ? (
                <>
                  <Box pt={2} mr={2} display="inline-block">
                    <Button
                      data-qa="add-primary-beneficiary-button"
                      variant="contained"
                      color="primary"
                      disabled={showForm || isLoading}
                      startIcon={<AddIcon />}
                      onClick={() => {
                        onAddClick();
                      }}
                    >
                      Add Beneficiary
                    </Button>
                  </Box>

                  {!showForm && (
                    <Box pt={2} display="inline-block">
                      <Button
                        variant="outlined"
                        color="primary"
                        disabled={isLoading}
                        type="submit"
                      >
                        Continue
                      </Button>
                    </Box>
                  )}
                </>
              ): null}
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
}

export default PrimaryBeneficiaries;
