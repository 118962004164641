// SessionTimeoutModal.tsx
import React from 'react';
import {
  Modal,
  Button,
  Grid,
  Fade,
  Box,
  IconButton,
  Typography,
  Divider,
} from '@mui/material';
import { useSessionTimeout } from './SessionTimeout';
import CancelIcon from '@mui/icons-material/Cancel';
import { useAuth0 } from '@auth0/auth0-react';

const SessionTimeoutModal = () => {
  const { logout } = useAuth0();

  const { isWarningVisible, resetTimer, countdown } = useSessionTimeout();
  const containerStyle = {
    position: 'absolute' as any,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '75%',
    maxWidth: 480,
    height: 'auto',
    maxHeight: '66%',
    bgcolor: 'white',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: 2,
    box: 42,
    p: 4,
    overflow: 'auto',
  };

  // styling from the top right corner
  const closeButtonStyle = {
    position: 'fixed' as any,
    top: 0,
    right: 0,
  };

  // styling for the content
  const contentStyle = {
    height: '100%',
  };

  // styling for extending the session button and logout button
  const buttonStyle = {
    display: 'flex',
    justifyContent: 'right',
  };

  return (
    <Modal open={isWarningVisible} onClose={resetTimer}>
      <Fade in={isWarningVisible}>
        <Box sx={containerStyle}>
          <Box sx={closeButtonStyle}>
            <IconButton
              data-qa="close-modal_button"
              color="primary"
              onClick={() => {
                resetTimer();
              }}
              aria-label="close modal"
              size="large"
            >
              <CancelIcon />
            </IconButton>
          </Box>
          <Box sx={contentStyle}>
            <>
              <Typography variant="h6" component="h2">
                Session Timeout{' '}
              </Typography>
              <Box pt={2} pb={2}>
                <Divider />
              </Box>
            </>
            <Typography variant="body1" component="h2">
            Your session will expire in {countdown} seconds. Do you want to extend
              your session?
            </Typography>
          </Box>
          <Box sx={buttonStyle}>
            <Button variant="contained" color="secondary" onClick={resetTimer}>
              Extend Session
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                logout({ logoutParams: { returnTo: window.location.origin } });
              }}
              sx={{ml: 2}}
            >
              Sign Out
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default SessionTimeoutModal;
