import { useAuth0 } from '@auth0/auth0-react';
import React, { createContext, useState, useEffect, useCallback, useRef } from 'react';

const SessionTimeoutContext = createContext(null);

export const SessionTimeoutProvider = ({ children }) => {
  const [isWarningVisible, setIsWarningVisible] = useState(false);
  const [countdown, setCountdown] = useState(60); // 1 minute countdown
  const timeoutIdRef = useRef(null);
  const warningTimeoutIdRef = useRef(null);
  const countdownIntervalRef = useRef(null);
  const activityTimeoutRef = useRef(null);
  const { isAuthenticated, logout } = useAuth0();

  const startTimer = useCallback(() => {
    if (timeoutIdRef.current) clearTimeout(timeoutIdRef.current);
    if (warningTimeoutIdRef.current) clearTimeout(warningTimeoutIdRef.current);
    if (countdownIntervalRef.current) clearInterval(countdownIntervalRef.current);

    const newWarningTimeoutId = setTimeout(() => {
      setIsWarningVisible(true);
      setCountdown(60); // Reset countdown to 1 minute
      countdownIntervalRef.current = setInterval(() => {
        setCountdown(prevCountdown => {
          if (prevCountdown <= 1) {
            clearInterval(countdownIntervalRef.current);
            return 0;
          }
          return prevCountdown - 1;
        });
      }, 1000);
    }, 3600000 - 60000); // 5 minutes - 1 minute warning

    const newTimeoutId = setTimeout(() => {
      setIsWarningVisible(false);
      logout({ logoutParams: { returnTo: window.location.origin } });
    }, 3600000); // 5 minutes

    warningTimeoutIdRef.current = newWarningTimeoutId;
    timeoutIdRef.current = newTimeoutId;
  }, [logout]);

  const resetTimer = useCallback(() => {
    setIsWarningVisible(false);
    startTimer();
  }, [startTimer]);

  const handleActivity = useCallback(() => {
    if (activityTimeoutRef.current) clearTimeout(activityTimeoutRef.current);
    activityTimeoutRef.current = setTimeout(() => {
      startTimer();
    }, 3600000); // 5 minutes of inactivity
  }, [startTimer]);

  useEffect(() => {
    if (isAuthenticated) {
      startTimer();
      window.addEventListener('mousemove', handleActivity);
      window.addEventListener('keydown', handleActivity);
    }
    return () => {
      if (timeoutIdRef.current) clearTimeout(timeoutIdRef.current);
      if (warningTimeoutIdRef.current) clearTimeout(warningTimeoutIdRef.current);
      if (countdownIntervalRef.current) clearInterval(countdownIntervalRef.current);
      if (activityTimeoutRef.current) clearTimeout(activityTimeoutRef.current);
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
    };
  }, [isAuthenticated, startTimer, handleActivity]);

  return (
    <SessionTimeoutContext.Provider value={{ isWarningVisible, countdown, resetTimer }}>
      {children}
    </SessionTimeoutContext.Provider>
  );
};

export const useSessionTimeout = () => React.useContext(SessionTimeoutContext);