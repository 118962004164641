import React, { useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Typography, Grid, Paper } from '@mui/material';

import { useUser } from '../auth/useUser';
import AccountSearchForm from '../components/form/accountSearch/AccountSearchForm';
import AccountSearchResults from '../components/form/accountSearch/AccountSearchResults';
import DesignatePrimaryBeneficiaries from '../components/beneficiaries/DesignatePrimaryBeneficiaries';
import DesignateSecondaryBeneficiaries from '../components/beneficiaries/DesignateSecondaryBeneficiaries';
import ReviewDocumentForm from '../components/form/newAccount/ReviewDocumentForm';
import Layout from '../components/Layout';
import FormPaper from '../components/FormPaper';
import { AccountOwner } from '../api/AccountOwnerApi.d';
import {
  addTransactionData,
  setSearchResponse,
  setSelectedAccount,
  skipStep,
  completeTransaction,
  rejectTransaction,
  useTransactionReducer,
} from './TransactionReducer';
import {
  BeneficiaryStatus,
  BeneficiaryStatusState,
  WorkflowDates,
} from '../api/BeneficiariesApi.d';
import {
  changeBeneficiariesStatus,
  createBeneficiariesDocument,
  getBeneficiariesDocument,
} from '../api/BeneficiariesApi';
import { getAccountOwner } from '../api/AccountOwnerApi';
import { getAccount } from '../api/AccountApi';
import { AccountStatus, TransactionType } from '../api/AccountApi.d';
import { useGlobalContext } from '../auth/useGlobalContext';
import TransactionStepper from './TransactionStepper';
import SkipChallenge from '../components/steps/SkipChallenge';
import {
  useUnsavedChangesWarning,
  RowDefinition,
} from '../components/useUnsavedChangesWarning';
import SiraAccountOwnerInfo from '../components/form/SiraAccountOwnerInfo';
import {
  updateLatestBeneficiariesStatus,
  useBeneficiariesReducer,
} from '../components/beneficiaries/BeneficiariesReducer';
import { IMMDocumentType } from '../api/ESignApi.d';
import SiraPageAlert from '../components/SiraPageAlert';
import { errorMessages } from '../utils/errorhandling.utils';

function DesignateBeneficiaries() {
  let isMounted = true;
  const [pageState] = useTransactionReducer();
  const { user } = useUser();
  const { addGlobalMessage } = useGlobalContext();
  const [beneficiariesState] = useBeneficiariesReducer();
  const queryParams = new URLSearchParams(useLocation().search);
  const [isLoading, setIsLoading] = useState(false as boolean);
  const [accountOwner, setAccountOwner] = useState({} as AccountOwner);
  const { query = '' } = pageState.searchResponse || {};
  const { accountOwnerId = '' } = pageState.selectedAccount;
  const { beneficiaryStatus, secondaryBeneficiaries = [] } =
    pageState.beneficiaryInformation;
  const isAwaiting = [
    BeneficiaryStatus.signature,
    BeneficiaryStatus.review,
  ].includes(beneficiaryStatus);
  const isActive = [BeneficiaryStatus.live, BeneficiaryStatus.review, BeneficiaryStatus.signature].includes(
    beneficiaryStatus
  );
  const showContingentChallenge = !secondaryBeneficiaries.length;
  const { UnsavedChangesPrompt, setUnsavedChanges } =
    useUnsavedChangesWarning();

  async function progressCurrentBeneficiariesStatus(
    finalizeWorkflow: boolean
  ): Promise<void> {
    await changeBeneficiariesStatus(
      user.organizationId,
      pageState.selectedAccount.accountId,
      pageState.selectedAccount.accountOwnerId,
      pageState.beneficiaryInformation.version,
      BeneficiaryStatusState.next,
      {} as WorkflowDates,
      user.token,
      user
    )
      .then((res) => {
        if (isMounted) {
          addTransactionData({ beneficiaryInformation: res.data }, false);
          updateLatestBeneficiariesStatus(res.data.beneficiaryStatus);
          if (finalizeWorkflow) {
            completeTransaction();
            setUnsavedChanges(null);
          }
        }
      })
      .catch(() => {
        if (isMounted) {
          rejectTransaction();
        }
      });
  }

  // Update the status imperatively after document creation
  // The api does this but doesn't send a payload back so we'll hardcode it here for now
  const handleCreateDocument = useCallback(() => {
    if (beneficiaryStatus === BeneficiaryStatus.pending) {
      addTransactionData(
        {
          beneficiaryInformation: {
            beneficiaryStatus: BeneficiaryStatus.signature,
          },
        },
        false
      );
    }
  }, [beneficiaryStatus]);

  // using this to set transaction deletion Values
  const setRouterPromptValues = (beneficiaryInformation) => {
    const transactionData: RowDefinition = {
      accountId: pageState.selectedAccount.accountId,
      accountOwnerId: pageState.selectedAccount.accountOwnerId,
      transactionType: TransactionType.beneficiaries,
      transactionId: beneficiaryInformation.version,
    };
    setUnsavedChanges(transactionData);
  };

  // Get the accountOwner for the account loaded
  async function updateAccountOwner(): Promise<void> {
    setIsLoading(true);

    await getAccountOwner(accountOwnerId, user.organizationId, user.token, user)
      .then((res) => {
        if (isMounted) {
          setIsLoading(false);
          setAccountOwner(res.data);
        }
      })
      .catch(() => {
        if (isMounted) {
          setIsLoading(false);
        }
      });
  }

  // Look up account and set it selected with query params passed
  async function fetchAndSetAccount(): Promise<void> {
    await getAccount(
      queryParams.get('accountId'),
      queryParams.get('accountOwnerId'),
      user.organizationId,
      user.token,
      user
    )
      .then((res) => {
        if (isMounted) {
          setSelectedAccount(res.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {

        if (isMounted) {
          setIsLoading(false);
 addGlobalMessage(errorMessages(err) || 'Could not fetch the preselected account'
          );
        }
      });
  }

  // Optionaly set account when passed query params
  async function preselectAccountInfo(): Promise<void> {
    if (queryParams.get('accountId')) {
      setIsLoading(true);
      await fetchAndSetAccount();
    }
  }

  // Generate the PDF in S3 and/or stream it
  const viewDocument = (): Promise<any> => {
    return isActive
      ? getBeneficiariesDocument(
          user.organizationId,
          pageState.selectedAccount.accountOwnerId,
          pageState.selectedAccount.accountId,
          pageState.beneficiaryInformation.version,
          user.token,
          '',
          user
        )
      : createBeneficiariesDocument(
          user.organizationId,
          pageState.selectedAccount.accountOwnerId,
          pageState.selectedAccount.accountId,
          pageState.beneficiaryInformation.version,
          user.token,
          user
        );
  };

  const designateBeneficiariesSteps = [
    {
      label: 'Find Owner and Account',
      stepContent: (
        <Box width="1" mt={4} mb={4}>
          <Grid container>
            <Grid item xs={12} md={8}>
              <Box mt={2} mb={4}>
                <AccountSearchForm setResponse={setSearchResponse} />
              </Box>
            </Grid>
          </Grid>
          {query && (
            <AccountSearchResults
              filterFunction={({
                account: {
                  accountStatus: statusMatcher = '' as AccountStatus,
                } = {},
              }) =>
                [AccountStatus.open, AccountStatus.closed].includes(
                  statusMatcher
                )
              }
              response={pageState.searchResponse}
              onResultClick={setSelectedAccount}
            />
          )}
        </Box>
      ),
    },
    {
      label: 'Name Primary Beneficiaries',
      stepContent: (
        <DesignatePrimaryBeneficiaries
          state={beneficiariesState}
          initializeAsPending
          onSave={(beneficiaryInformation) => {
            addTransactionData({ beneficiaryInformation });
            setRouterPromptValues(beneficiaryInformation);
          }}
          account={pageState.selectedAccount}
          accountOwner={accountOwner}
          onSkipToSignature={(beneficiaryInformation) => {
            addTransactionData({ beneficiaryInformation }, false);
            skipStep(3);
          }}
          accountOwnerProfile={false}
        />
      ),
    },
    {
      label: 'Name Contingent Beneficiaries',
      stepContent: (
        <SkipChallenge
          shouldDisplay={showContingentChallenge}
          onSkip={() => skipStep(pageState.activeStep + 1)}
          skipText="Skip"
          acceptText="Add Beneficiaries"
        >
          <DesignateSecondaryBeneficiaries
            state={beneficiariesState}
            onSave={(beneficiaryInformation) => {
              addTransactionData({ beneficiaryInformation });
            }}
            account={pageState.selectedAccount}
            accountOwner={accountOwner}
            onSkipToSignature={(beneficiaryInformation) => {
              addTransactionData({ beneficiaryInformation }, false);
              skipStep(3);
            }}
            accountOwnerProfile={false}
          />
        </SkipChallenge>
      ),
    },
    {
      label: 'Review and Sign Document',
      stepContent: (
        <>
          <ReviewDocumentForm
            searchResult={pageState.selectedAccount}
            allowSignature={isAwaiting}
            submitName="Done"
            hideStepButtonBar={pageState.completed}
            getDocument={viewDocument}
            onGetDocument={handleCreateDocument}
            onSubmit={async () => {
              if (isAwaiting) {
                await progressCurrentBeneficiariesStatus(true);
              }
            }}
            documentType={IMMDocumentType.beneficiaries}
            transactionName="Beneficiaries"
          />
          {isActive && (pageState.completed || pageState.rejected) && (
            <SiraPageAlert
              pageStatus={pageState.rejected}
              transactionName="Beneficiaries"
              accountId={pageState.selectedAccount.accountId}
              accountOwnerId={accountOwnerId}
            />
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    if (user.token || user.organizationId) {
      preselectAccountInfo();
    }

    return () => {
      isMounted = false;
    };
  }, [user.token, user.organizationId]);

  useEffect(() => {
    setIsLoading(true);
    updateAccountOwner();
  }, [accountOwnerId]);

  return (
    <Layout>
      <FormPaper>
        <>
          <Typography variant="overline" gutterBottom>
            Transactions
          </Typography>

          <Typography color="secondary" variant="h1" gutterBottom>
            Update Beneficiaries
          </Typography>

          <Typography variant="subtitle1" gutterBottom>
            Find the account owner and select the correct account to designate
            or update death beneficiaries.
          </Typography>

          {pageState.selectedAccount.accountId && (
            <Box mt={2}>
              <Paper elevation={3}>
                <SiraAccountOwnerInfo
                  selectedAccount={pageState.selectedAccount}
                  accountOwner={accountOwner}
                />
              </Paper>
            </Box>
          )}

          <Box mt={5}>
            <TransactionStepper
              steps={designateBeneficiariesSteps}
              activeStep={pageState.activeStep}
              isLoading={isLoading}
              onStepClick={(index) => {
                skipStep(index);
              }}
            />
          </Box>
          {UnsavedChangesPrompt}
        </>
      </FormPaper>
    </Layout>
  );
}

export default DesignateBeneficiaries;
