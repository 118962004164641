import axios from 'axios';

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL, // Set your base URL here
  timeout: 10000, // Set a timeout if needed
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    // Modify the URL to remove the trailing slash
    if (config.url) {
      // Remove the trailing slash if it exists
      config.url = config.url.replace(/\/$/, '');
   }
    return config;
  },
  (error) => {
    // Handle the error
    return Promise.reject(error);
  }
);

export default axiosInstance;