// We will allow the user to enter some data into the form to verify them as a user
// We will then use this data to verify the user has an account before returning to the dashboard to make a user call.

import {
  Alert,
  Box,
  Grid,
  StyledEngineProvider,
  ThemeProvider,
  Typography,
} from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import ReportIcon from '@mui/icons-material/Report';

import Layout from '../components/Layout';
import FormPaper from '../components/FormPaper';
import createOwnerTheme from '../themes/ownerTheme';
import { useGlobalContext } from '../auth/useGlobalContext';
import StepButtonBar from '../components/steps/StepButtonBar';
import SiraTextField from '../components/form/SiraTextField';
import TaxIdField from '../components/form/TaxIdField';
import SiraDateField, {
  dateValidation,
} from '../components/form/SiraDateField';
import { SiraUser, useUser } from '../auth/useUser';
import Loading from '../components/Loading';
import AccountOwnerScreen from './OwnerProfile';
import { verifyUser } from '../api/UserApi';
import demoTheme from '../themes/demoTheme';
import { useTheme } from '@mui/material/styles';
import parse from 'react-html-parser';

export interface CustomerVerifyUser {
  // TODO
  lastName: string;
  taxpayerIdNumber: string;
  dateOfBirth: string;
}

export const REVIEW_INIT: CustomerVerifyUser = {
  lastName: '',
  taxpayerIdNumber: '',
  dateOfBirth: '',
};

export interface CustomerPortalVerifyUserProps {
  // TODO
  initialValues: CustomerVerifyUser;
  onSubmit: Function;
}

export const REVIEW_SCHEMA = {
  lastName: yup.string().required().max(40).label('Last Name'),
  taxpayerIdNumber: yup
    .string()
    .required()
    .matches(/\d{3}-\d{2}-\d{4}/, 'SSN must be valid ssn')
    .label('SSN'),
  dateOfBirth: dateValidation().required().label('Date of Birth'),
};

export function CustomerPortalVerifyUser(props: CustomerPortalVerifyUserProps) {
  const { onSubmit, initialValues } = props;
  const { organization } = useGlobalContext();
  const { user } = useUser();
  const { email } = user;
  const [ownerTheme, setOwnerTheme] = React.useState(false as boolean);
  const theme = useTheme();

  useEffect(() => {
    if (organization.secondaryColorRGB) {
      setOwnerTheme(true);
    } else {
      setOwnerTheme(false);
    }
  }, [organization]);

  // TODO: Add validation schema
  // TODO: rearrange components to not wrap in Formik
  // we wait for organization data to come back before we can create the theme
  // we set the user email coming in from auth0 to the email field and disable it

  if (!ownerTheme) {
    return <Loading />;
  }

  return ownerTheme ? (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={createOwnerTheme(organization)}>
        <Layout>
          <FormPaper>
            <Formik
              initialValues={initialValues}
              onSubmit={async (values) => {
                await onSubmit({ ...values });
              }}
              validationSchema={yup.object(REVIEW_SCHEMA)}
            >
              {({ isSubmitting }) => {
                return (
                  <Form autoComplete="off">
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={7}>
                        <Box mt={5}>
                          <Typography
                            sx={{
                              fontFamily: 'Lato',
                              fontWeight: '400',
                              fontSize: '2.125rem',
                              lineHeight: '1.235',
                              fontVariantLigatures: 'none',
                              color: theme.palette.text.primary,
                              paddingBottom: '24px',
                            }}
                          >
                            Confirm Your Identity
                          </Typography>
                          <Typography variant="body1" flexWrap="wrap">
                            Enter the following information to verify you have
                            an IRA or HSA profile with <i>{organization.name}</i>. This
                            information will only be used to match against our
                            records.
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={7}>
                        <SiraTextField
                          name="lastName"
                          label="Last Name"
                          InputProps={{
                            autoComplete: 'new-password',
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={7}>
                        <SiraDateField name="dateOfBirth" label="Birth Date" />
                      </Grid>

                      <Grid item xs={12} sm={7}>
                        <TaxIdField name="taxpayerIdNumber" label="SSN" ssn />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <SiraTextField
                          name="emailAddress"
                          label="Email"
                          value={email}
                          disabled
                        />
                      </Grid>
                    </Grid>
                    <StepButtonBar
                      isSubmitting={isSubmitting}
                      submitName="Verify"
                    />
                  </Form>
                );
              }}
            </Formik>
          </FormPaper>
        </Layout>
      </ThemeProvider>
    </StyledEngineProvider>
  ) : (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={demoTheme}>
        <FormPaper>
          <>
            <Box textAlign="center" maxWidth="sm" ml="auto" mr="auto">
              <Typography variant="h4" pb={3}>
                Branding Error
              </Typography>
              <ReportIcon color="error" sx={{ fontSize: '126px' }} />
              <Typography variant="h3" gutterBottom>
                Oops!
              </Typography>
              <Typography variant="body1" flexWrap="wrap">
                Error connecting to the account owner portal. Contact your
                financial organization for assistance.
              </Typography>
            </Box>
            <Box m={3}>
              <Alert severity="error">Branding Error</Alert>
            </Box>
          </>
        </FormPaper>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default function CustomerPortal() {
  const { user } = useUser();
  const [userInformation, setUserInformation] = useState({} as SiraUser);
  const { organization, customerPortalUser } = useGlobalContext();
  const [ownerTheme, setOwnerTheme] = useState({} as any);
  const [accountVerified, setAccountVerified] = useState(
    customerPortalUser as boolean
  );
  const [nonExistantAccountError, setNonExistantAccountError] = useState(
    customerPortalUser as boolean
  );
  const [organizationErrorBody, setOrganizationErrorBody] = useState<string>();
  const [organizationErrorHeader, setOrganizationErrorHeader] = useState<string>();

  useEffect(() => {
    if (user && user.roles) {
      setUserInformation(user);
    }
  }, [user.roles]);

  // organization values
  useEffect(() => {
    if (organization.secondaryColorRGB) {
      setOwnerTheme(createOwnerTheme(organization));
      const {
        accountOwnerPortalCustomError,
        accountOwnerPortalCustomErrorHeader,
      } = organization;

      const splitValues = accountOwnerPortalCustomError ? accountOwnerPortalCustomError.split(', object: ') : '';

    // remove the markup: from the string and object: from the string
    const plainMarkup = splitValues ? splitValues[0].replace('markup: ', ''):'';

      setOrganizationErrorBody(plainMarkup);
      setOrganizationErrorHeader(accountOwnerPortalCustomErrorHeader);
    }
  }, [organization]);

  useEffect(() => {
    setAccountVerified(customerPortalUser);
  }, [customerPortalUser]);

  // call create process when user cannot be found
  // after user has been created, we will call account verification form to have user enter additional information for account lookup
  // we will use this additional information to verify the user has an acount before returning to the dashboard to make a user call.
  async function verifyUserInformation(data: CustomerVerifyUser) {
    // call post user api to verify user
    await verifyUser(data, user.organizationId, user.token)
      .then((response) => {
        if (response) {
          setAccountVerified(true);
          setNonExistantAccountError(false);
        }
      })
      .catch(() => {
        setAccountVerified(false);
        setNonExistantAccountError(true);
      });
  }

  // Display and error message if the user does not exist
  if (!accountVerified && nonExistantAccountError) {
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={ownerTheme}>
          <Layout>
            <FormPaper>
              <Box textAlign="center" maxWidth="sm" ml="auto" mr="auto">
                <Typography
                  sx={{
                    fontFamily: 'Lato',
                    fontWeight: '400',
                    fontSize: '2.125rem',
                    lineHeight: '1.235',
                    fontVariantLigatures: 'none',
                    color: ownerTheme.palette.text.primary,
                    paddingBottom: '24px',
                  }}
                >
                  Confirm Your Identity
                </Typography>
                <ReportIcon color="error" sx={{ fontSize: '126px' }} />
                <Typography variant="h4" gutterBottom>
                  {organizationErrorHeader !== undefined
                    ? organizationErrorHeader
                    : 'Oops!'}{' '}
                </Typography>
                <Typography variant="body1" flexWrap="wrap">
                  {organizationErrorHeader !== undefined
                    ? parse(organizationErrorBody)
                    : 'We were unable to find an account with the information you provided. Please contact your organization administrator to verify your account.'}
                </Typography>
              </Box>
            </FormPaper>
          </Layout>
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }

  return !accountVerified && !nonExistantAccountError ? (
    <CustomerPortalVerifyUser
      initialValues={{
        ...REVIEW_INIT,
      }}
      onSubmit={async (values: any) => {
        verifyUserInformation(values);
      }}
    />
  ) : (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={ownerTheme}>
        <AccountOwnerScreen userInfo={userInformation} />
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
