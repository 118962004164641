import React from 'react';
import { Box, Typography, Grid, Paper } from '@mui/material';
import { useLocation } from 'react-router-dom';

import AccountSearchForm from '../components/form/accountSearch/AccountSearchForm';
import AccountSearchResults from '../components/form/accountSearch/AccountSearchResults';
import Layout from '../components/Layout';
import FormPaper from '../components/FormPaper';
import { AccountOwner } from '../api/AccountOwnerApi.d';
import {
  setSearchResponse,
  setSelectedAccount,
  skipStep,
  completeTransaction,
  rejectTransaction,
  useTransactionReducer,
  addTransactionData,
} from './TransactionReducer';
import ContributionInfoForm, {
  CONTRIBUTION_INFO_INIT,
} from '../components/form/newAccount/ContributionInfoForm';
import { useUser } from '../auth/useUser';
import ContributionAllocationForm, {
  ALLOCATION_INIT,
} from '../components/form/newAccount/ContributionAllocationForm';
import {
  AccountContribution,
  ContributionStatus,
  ContributionStatusResponse,
  ContributionStatusState,
} from '../api/ContributionApi.d';
import { Allocation } from '../api/InvestmentRateApi.d';
import ReviewDocumentForm from '../components/form/newAccount/ReviewDocumentForm';
import TransactionStepper, { TransactionStep } from './TransactionStepper';
import {
  changeContributionStatus,
  createContributionDocument,
  createOrSaveContributionInformation,
  getAccountContribution,
  getContributionDocument,
} from '../api/ContributionApi';
import { WorkflowDates } from '../api/BeneficiariesApi.d';
import { useGlobalContext } from '../auth/useGlobalContext';
import { getAccount } from '../api/AccountApi';
import { getAccountOwner } from '../api/AccountOwnerApi';
import { createOrSaveAllocations } from '../api/InvestmentRateApi';
import { Account, AccountStatus, TransactionType } from '../api/AccountApi.d';
import {
  useUnsavedChangesWarning,
  RowDefinition,
} from '../components/useUnsavedChangesWarning';
import SiraAccountOwnerInfo from '../components/form/SiraAccountOwnerInfo';
import { IMMDocumentType } from '../api/ESignApi.d';
import SkipChallenge from '../components/steps/SkipChallenge';
import SiraPageAlert from '../components/SiraPageAlert';
import { errorMessages } from '../utils/errorhandling.utils';

function MakeContribution() {
  let isMounted = true;
  const { user } = useUser();
  const { addGlobalMessage, organization } = useGlobalContext();
  const queryParams = new URLSearchParams(useLocation().search);
  const [pageState] = useTransactionReducer();
  const { query = '' } = pageState.searchResponse || {};
  const [isLoading, setIsLoading] = React.useState(false);
  const [accountOwner, setAccountOwner] = React.useState({} as AccountOwner);
  const [allocations, setAllocations] = React.useState([] as Array<Allocation>);
  const { contributionId, contributionStatus } =
    pageState.contributionInformation;
  const { accountId, accountOwnerId = '' } = pageState.selectedAccount;
  const isAwaiting = [
    ContributionStatus.signature,
    ContributionStatus.review,
    ContributionStatus.submitOwner,
  ].includes(contributionStatus);
  const isActive = [
    ContributionStatus.active,
    ContributionStatus.review,
  ].includes(contributionStatus);
  const isFinalizeStatus = [
    ContributionStatus.active,
    ContributionStatus.review,
  ].includes(contributionStatus);
  const loadingExistingContribution =
    queryParams.get('accountId') && queryParams.get('contributionId');
  const { UnsavedChangesPrompt, setUnsavedChanges } =
    useUnsavedChangesWarning();

  // Advance the contribution status to the next
  const progressContributionStatus = async (
    finalizeWorkflow: boolean,
  ): Promise<void> => {
    const response = { errorMessage: null, data: null };

    await changeContributionStatus(
      user.organizationId,
      accountId,
      accountOwnerId,
      contributionId,
      ContributionStatusState.next,
      {} as WorkflowDates,
      user.token,
      user,
    )
      .then((res) => {
        response.data = res.data;
        if (isMounted) {
          // Set latest status
          addTransactionData({ contributionInformation: res.data }, false);
          if (finalizeWorkflow) {
            completeTransaction();
            setUnsavedChanges(null);
          }
        }
      })
      .catch((err) => {
        response.errorMessage =
          err?.response?.data?.message || 'Error Finalizing Contribution';
        if (isMounted) {
          rejectTransaction();
        }
      });
  };

  // Revert transaction to pending before saving to allow updates
  const revertToPending = async (): Promise<ContributionStatus> => {
    const response: ContributionStatusResponse = await changeContributionStatus(
      user.organizationId,
      accountId,
      accountOwnerId,
      contributionId,
      ContributionStatusState.previous,
      {} as WorkflowDates,
      user.token,
      user,
    );
    const { contributionStatus: newContributionStatus } = response.data;

    if (response.errorMessage) {
      addGlobalMessage('Error reverting contribution status');
      return contributionStatus;
    }
    if (isMounted) {
      addTransactionData(
        {
          contributionInformation: {
            contributionStatus: newContributionStatus,
          },
        },
        false,
      );
    }

    return newContributionStatus;
  };

  // Update transaction or create if no ID provided (revert if updating AWAITING_SIGNATURE)
  const saveContribution = async (data: AccountContribution): Promise<void> => {
    const merged = data; // Merge a reverted status immediately when saving
    setIsLoading(true);

    if (isAwaiting) {
      const newContributionStatus = await revertToPending();
      merged.contributionStatus = newContributionStatus;
    }

    await createOrSaveContributionInformation(
      merged,
      user.organizationId,
      accountOwnerId,
      accountId,
      contributionId,
      user.token,
      user,
    )
      .then((res) => {
        if (isMounted) {
          addTransactionData({ contributionInformation: res.data });
          setIsLoading(false);
        }
        const transactionData: RowDefinition = {
          accountId: pageState.selectedAccount.accountId,
          accountOwnerId: pageState.selectedAccount.accountOwnerId,
          transactionType: TransactionType.contribution,
          contributionId: res.data.contributionId,
        };
        setUnsavedChanges(transactionData);
      })
      .catch((err) => {
        if (isMounted) {
          setIsLoading(false);
          addGlobalMessage(errorMessages(err) || 'Error saving contribution');
        }
      });
  };

  // Send then store latest allocations
  const updateAndSaveAllocations = async (
    data: Array<Allocation>,
  ): Promise<void> => {
    setIsLoading(true);

    if (isAwaiting) {
      await revertToPending();
    }

    const response = await createOrSaveAllocations(
      data,
      user.organizationId,
      accountId,
      accountOwnerId,
      contributionId,
      user.token,
      user,
    );

    if (isMounted) {
      if (response.errorMessage) {
        addGlobalMessage(
          response.errorMessage || 'Error saving contribution allocations',
        );
      } else {
        setAllocations(response.data);
        skipStep(3);
      }

      setIsLoading(false);
    }
  };

  // Get the accountOwner for the account loaded
  const updateAccountOwner = async (ownerId): Promise<void> => {
    setIsLoading(true);

    await getAccountOwner(ownerId, user.organizationId, user.token, user)
      .then((res) => {
        if (isMounted) {
          setAccountOwner(res.data);
        }
      })
      .catch(() => {
        if (isMounted) {
          setIsLoading(false);
        }
      });
  };

  // Look up account and set it selected with query params passed
  async function fetchAndSetAccount(
    id: string,
    ownerId: string,
  ): Promise<void> {
    await getAccount(id, ownerId, user.organizationId, user.token, user)
      .then((res) => {
        if (isMounted) {
          setSelectedAccount(res.data);
          updateAccountOwner(ownerId);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        if (isMounted) {
          setIsLoading(false);
          addGlobalMessage(
            errorMessages(err) || 'Could not fetch the preselected account',
          );
        }
      });
  }

  // Update the status imperatively after document creation
  // The api does this but doesn't send a payload back so we'll hardcode it here for now
  const handleCreateDocument = () => {
    if (contributionStatus === ContributionStatus.pending) {
      addTransactionData(
        {
          contributionInformation: {
            contributionStatus: ContributionStatus.signature,
          },
        },
        false,
      );
    }
  };

  // Generate the PDF in S3 and/or stream it
  const viewDocument = (): Promise<any> => {
    return isAwaiting || isActive
      ? getContributionDocument(
          user.organizationId,
          accountOwnerId,
          accountId,
          contributionId,
          user.token,
          user,
        )
      : createContributionDocument(
          user.organizationId,
          accountOwnerId,
          accountId,
          contributionId,
          user.token,
          user,
        );
  };

  // Lookup contribution and set it with query params passed
  async function fetchAndSetContributionInfo(): Promise<void> {
    await getAccountContribution(
      queryParams.get('accountId'),
      queryParams.get('accountOwnerId'),
      user.organizationId,
      queryParams.get('contributionId'),
      user.token,
      user,
    )
      .then((res) => {
        if (isMounted) {
          const skipToEnd = [
            ContributionStatus.signature,
            ContributionStatus.review,
            ContributionStatus.submitOwner,
          ].includes(res.data.contributionStatus);

          addTransactionData({ contributionInformation: res.data }, false);

          if (skipToEnd) {
            skipStep(3);
            setIsLoading(false);
          }
        }
      })
      .catch((err) => {
        if (isMounted) {
          setIsLoading(false);
          addGlobalMessage(
            errorMessages(err) || 'Error fetching contribution info',
          );
        }
      });
  }

  // Optionaly set account and contribution when passed query params
  async function preselectAccountAndSetContributionInfo(): Promise<void> {
    if (queryParams.get('accountId')) {
      setIsLoading(true);
      await fetchAndSetAccount(
        queryParams.get('accountId'),
        queryParams.get('accountOwnerId'),
      );
    }
    if (loadingExistingContribution) {
      setIsLoading(true);
      await fetchAndSetContributionInfo();
    }
  }

  async function loadSelectedAccount(account: Account) {
    await fetchAndSetAccount(account.accountId, account.accountOwnerId);
  }

  const makeContributionSteps: Array<TransactionStep> = [
    {
      label: 'Find Owner and Account',
      stepContent: (
        <Box width="1" mt={4} mb={4}>
          <Grid container>
            <Grid item xs={12} md={8}>
              <Box mt={2} mb={4}>
                <AccountSearchForm
                  initialValues={{ query: queryParams.get('query') || '' }}
                  setResponse={setSearchResponse}
                />
              </Box>
            </Grid>
          </Grid>
          {query && (
            <AccountSearchResults
              filterFunction={({
                account: {
                  accountStatus: statusMatcher = '' as AccountStatus,
                } = {},
              }) =>
                [
                  AccountStatus.open,
                  AccountStatus.closed,
                  AccountStatus.pending,
                ].includes(statusMatcher)
              }
              response={pageState.searchResponse}
              onResultClick={(account) => {
                loadSelectedAccount(account);
              }}
            />
          )}
        </Box>
      ),
    },
    {
      label: 'Provide Deposit Information',
      stepContent: (
        <ContributionInfoForm
          account={pageState.selectedAccount}
          initialValues={{
            ...CONTRIBUTION_INFO_INIT,
            ...pageState.contributionInformation,
          }}
          onSubmit={saveContribution}
          accountOwner={accountOwner}
        />
      ),
    },
    {
      label: 'Select Investment Options',
      stepContent: (
        <SkipChallenge
          onSkip={() => skipStep(pageState.activeStep + 1)}
          skipText="Skip"
          acceptText="Allocate Assets"
        >
          <ContributionAllocationForm
            existingAllocations={[...ALLOCATION_INIT, ...allocations]}
            contributionInfo={pageState.contributionInformation}
            onSubmit={updateAndSaveAllocations}
            accountOwnerId={accountOwnerId}
          />
        </SkipChallenge>
      ),
    },
    {
      label: 'Review and Sign Document',
      stepContent: (
        <>
          <ReviewDocumentForm
            searchResult={pageState.selectedAccount}
            getDocument={viewDocument}
            submitName="Done"
            hideStepButtonBar={pageState.completed}
            allowSignature={isAwaiting}
            onGetDocument={handleCreateDocument}
            onSubmit={async () => {
              if (isAwaiting) {
                await progressContributionStatus(true);
              }
            }}
            documentType={IMMDocumentType.contribution}
            transactionName="Contribution"
            transactionStatus={
              pageState.contributionInformation.contributionStatus
            }
          />
          {isFinalizeStatus && (pageState.completed || pageState.rejected) && (
            <SiraPageAlert
              pageStatus={pageState.rejected}
              transactionName="Contribution"
              accountId={accountId}
              accountOwnerId={accountOwnerId}
            />
          )}
        </>
      ),
    },
  ];

  React.useEffect(() => {
    if (user.token && user.organizationId) {
      preselectAccountAndSetContributionInfo();
    }

    return () => {
      isMounted = false;
    };
  }, [user.token, user.organizationId]);

  React.useEffect(() => {
    if (accountOwnerId && loadingExistingContribution) {
      setIsLoading(true);
      updateAccountOwner(accountOwnerId);
    }
  }, [accountOwnerId]);

  return (
    <Layout>
      <FormPaper>
        <>
          <Typography variant="overline" gutterBottom>
            Transactions
          </Typography>

          <Typography color="secondary" variant="h1" gutterBottom>
            Deposit Money
          </Typography>

          <Typography variant="subtitle1" gutterBottom>
            Find the account owner and select the correct account to make the
            contribution to an account.
          </Typography>

          {accountId && (
            <Box mt={2}>
              <Paper elevation={3}>
                <SiraAccountOwnerInfo
                  selectedAccount={pageState.selectedAccount}
                  accountOwner={accountOwner}
                />
              </Paper>
            </Box>
          )}

          <Box mt={5}>
            <TransactionStepper
              steps={
                organization.displayInvestments
                  ? makeContributionSteps
                  : makeContributionSteps.filter(
                      (step) => step.label !== 'Select Investment Options',
                    )
              }
              activeStep={pageState.activeStep}
              isLoading={isLoading}
              onStepClick={(index) => {
                if (
                  !loadingExistingContribution ||
                  (loadingExistingContribution && index > 0)
                ) {
                  skipStep(index);
                }
              }}
            />
          </Box>
          {UnsavedChangesPrompt}
        </>
      </FormPaper>
    </Layout>
  );
}

export default MakeContribution;
