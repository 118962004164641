import axios from 'axios';
import format from 'string-format';

import { prepareBinaryJson, removeEmptyStrings, setBaseUrl } from './api.utils';
import { WorkflowDates } from './BeneficiariesApi.d';
import {
  AccountContribution,
  ContributionImportColumnsResponse,
  ContributionStatusResponse,
  ContributionStatusState,
} from './ContributionApi.d';
import { UserRole } from './UserApi.d';
import axiosInstance from '../utils/axiosInterceptor';

const urlBase = process.env.REACT_APP_API_BASE_URL;
const customerPortalUrl = '/api/customerPortal';
const branchUrl = '/api';

const contributionDocumentUrl =
  '/org/{0.orgId}/accountOwner/{0.accountOwnerId}/account/{0.accountId}/contribution/{0.contributionId}/document';
const contributionsUrl = '/api/org/{0.orgId}/contribution';
const activeContributionsUrl = '/api/org/{0.orgId}/activeContributions';
const activeContributionsSummaryUrl =
  '/api/org/{0.orgId}/activeContributionsSummarized';
const contributionUrl =
  '/org/{0.orgId}/accountOwner/{0.accountOwnerId}/account/{0.accountId}/contribution/{0.contributionId}';
const contributionStatusUrl =
  '/org/{0.orgId}/accountOwner/{0.accountOwnerId}/account/{0.accountId}/contribution/{0.contributionId}/status?state={0.state}';
const contributionDeleteUrl =
  '/org/{0.orgId}/accountOwner/{0.accountOwnerId}/account/{0.accountId}/contribution/{0.contributionId}';
const contributionImportColumsUrl =
  '/api/org/{0.orgId}/upload/contributionImportColumns';
  const contributionImportUrl = '/api/org/{0.orgId}/upload/contributionImport';
  const contributionExportUrl = '/api/org/{0.orgId}/contributionListExport';
  const contributionTaxCorrection = '/api/org/{0.orgId}/flat/taxFile/contribution/correction';
  const fmvTaxCorrection = '/api/org/{0.orgId}/flat/taxFile/fmv/correction';

function contributionRequestFactory(
  contribution: AccountContribution
): AccountContribution {
  const {
    accountId,
    amount,
    contributionId,
    contributionStatus,
    dateOnCheck,
    depositMethod,
    depositType,
    effectiveDate,
    fromAccountNumber,
    fromAccountType,
    fromFinancialOrganization,
    payableTo,
    postponedCode,
    postponedReason,
    repaymentCode,
    signedDate,
    taxYear,
    dateOfWithdrawal,
    depositorFirstName,
    depositorLastName,
    depositorPhoneNumber,
    depositorEmailAddress,
  } = contribution;

  return {
    accountId,
    amount,
    contributionId,
    contributionStatus,
    dateOnCheck,
    depositMethod,
    depositType,
    effectiveDate,
    fromAccountNumber,
    fromAccountType,
    fromFinancialOrganization,
    payableTo,
    postponedCode,
    postponedReason,
    repaymentCode,
    signedDate,
    taxYear,
    dateOfWithdrawal,
    depositorFirstName,
    depositorLastName,
    depositorPhoneNumber,
    depositorEmailAddress,
  };
}

export async function getContributionDocument(
  orgId: string,
  accountOwnerId: string,
  accountId: string,
  contributionId: string,
  token: string,
  user: any,
  versionID: string = '',
) {
  const url = `${urlBase}${format(`${setBaseUrl(user)}${contributionDocumentUrl}`, {
    orgId,
    accountOwnerId,
    accountId,
    contributionId,
  })}`;

  const params = removeEmptyStrings({ versionID });

  return axiosInstance.get(url, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function createContributionDocument(
  orgId: string,
  accountOwnerId: string,
  accountId: string,
  contributionId: string,
  token: string,
  user
) {
  const url = `${urlBase}${format(`${setBaseUrl(user)}${contributionDocumentUrl}`, {
    orgId,
    accountOwnerId,
    accountId,
    contributionId,
  })}`;

  return axiosInstance.post(url, undefined, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function createOrSaveContributionInformation(
  data: AccountContribution,
  orgId: string,
  accountOwnerId: string,
  accountId: string,
  contributionId: string = '',
  token: string,
  user: any,
): Promise<ContributionStatusResponse> {
  const url = `${urlBase}${format(`${setBaseUrl(user)}${contributionUrl}`, {
    orgId,
    accountId,
    accountOwnerId,
    contributionId,
  })}`;

  const dataNorm = removeEmptyStrings(contributionRequestFactory(data));

  if (contributionId) {
    return axiosInstance.put(url, dataNorm, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  return axiosInstance.post(url, dataNorm, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getAccountContributions(
  accountId: string,
  accountOwnerId: string,
  orgId: string,
  token: string,
  user: any,
  status?: any,
) {
  let base = branchUrl;
  if (user.roles.includes(UserRole.accountOwner)) {
    base = customerPortalUrl;
  }
  const url = `${urlBase}${format(`${base}${contributionUrl}`, {
    orgId,
    accountId,
    accountOwnerId,
    contributionId: '',
  })}`;

  const params = removeEmptyStrings({ status });

  return axiosInstance.get(url, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getAccountContribution(
  accountId: string,
  accountOwnerId: string,
  orgId: string,
  contributionId: string,
  token: string,
  user: any,
) {
  let base = branchUrl;
  if (user.roles.includes(UserRole.accountOwner)) {
    base = customerPortalUrl;
  }

  const url = `${urlBase}${format(`${base}${contributionUrl}`, {
    orgId,
    accountId,
    accountOwnerId,
    contributionId,
  })}`;

  return axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function deleteAccountContribution(
  accountId: string,
  accountOwnerId: string,
  orgId: string,
  contributionId: string,
  token: string,
  user
) {
  const url = `${urlBase}${format(`${setBaseUrl(user)}${contributionDeleteUrl}`, {
    orgId,
    accountId,
    accountOwnerId,
    contributionId,
  })}`;

  return axiosInstance.delete(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function changeContributionStatus(
  orgId: string,
  accountId: string,
  accountOwnerId: string,
  contributionId: string,
  state: ContributionStatusState,
  workflowDates: WorkflowDates = {},
  token: string,
  user: any,
): Promise<ContributionStatusResponse> {
  const url = `${urlBase}${format(`${setBaseUrl(user)}${contributionStatusUrl}`, {
    orgId,
    accountId,
    accountOwnerId,
    contributionId,
    state,
  })}`;

  return axiosInstance.put(url, workflowDates, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getOrgContributions(
  orgId: string,
  status: string | Array<string>,
  token: string
) {
  const url = `${urlBase}${format(contributionsUrl, {
    orgId,
  })}`;
  const params = removeEmptyStrings({ status });

  return axiosInstance.get(url, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getActiveContributions(
  orgId: string,
  search: {
    startDate: string;
    endDate?: string;
  },
  token: string
) {
  const url = `${urlBase}${format(activeContributionsUrl, { orgId })}`;
  const params = removeEmptyStrings(search);

  return axiosInstance.get(url, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function exportDeposits(
  orgId: string,
  token: string,
  fileType:string,
  startDate:string,
  endDate:string ,
  taxYears?: Array<string>,
  accountTypes?:Array<string>,
): Promise<any> {
  const url = `${urlBase}${format(contributionExportUrl, {
    orgId,
  })}`;

  return axiosInstance.get(url, {
    params: {
      fileType,
      startDate,
      endDate,        
      taxYears,
      accountTypes,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType: 'blob',
  });
}

export async function getActiveContributionsSummarized(
  orgId: string,
  search: {
    startDate: string;
    endDate?: string;
  },
  token: string
) {
  const url = `${urlBase}${format(activeContributionsSummaryUrl, { orgId })}`;
  const params = removeEmptyStrings(search);

  return axiosInstance.get(url, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function importContributionsColumns(
  files: Array<File>,
  orgId: string,
  token: string
): Promise<ContributionImportColumnsResponse> {
  const url = `${urlBase}${format(contributionImportColumsUrl, {
    orgId,
  })}`;

  const formData = new FormData();
  formData.append('file', files[0]);

  return axiosInstance.post(url, formData, {
    headers: {
      'content-type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function importContributions(
  files: Array<File>,
  headerMappings: any,
  orgId: string,
  token: string
): Promise<any> {
  const url = `${urlBase}${format(contributionImportUrl, {
    orgId,
  })}`;

  const formData = new FormData();

  formData.append('file', files[0]);
  if (headerMappings) {
    formData.append('headerMappings', prepareBinaryJson(headerMappings));
  }

  return axiosInstance.post(url, formData, {
    headers: {
      'content-type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function updateContributionCorrection(
  orgId: string,
  token: string,
  irsTaxFileUploadId: string,
) {
  const url = `${urlBase}${format(contributionTaxCorrection, { orgId })}`;
  const params = {irsTaxFileUploadId};

  return axiosInstance.put(url, null, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function updateFMVCorrection(
  orgId: string,
  token: string,
  irsTaxFileUploadId: string,
) {
  const url = `${urlBase}${format(fmvTaxCorrection, { orgId })}`;
  const params = {irsTaxFileUploadId};

  return axiosInstance.put(url, null, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}