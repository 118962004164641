import React, { useEffect, useState } from 'react';
import { Grid, Box } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import SiraRadioField, { RadioGroupOption } from '../SiraRadioField';
import DepositTypeFields from './DepositTypeFields';
import getDepositTypes from '../newAccount/getDepositTypes';
import { AccountContribution } from '../../../api/ContributionApi.d';
import { Account } from '../../../api/AccountApi.d';
import { useGlobalContext } from '../../../auth/useGlobalContext';
import { AccountOwner } from '../../../api/AccountOwnerApi.d';
import { StateTaxDeadline } from '../../../api/StatesApi.d';
import { currentTaxYear } from '../../../app.constants';
import { getStateTaxDeadline } from '../../../api/StatesApi';
import { useUser } from '../../../auth/useUser';
import { errorMessages } from '../../../utils/errorhandling.utils';

export interface DepositTypeFormProps {
  account: Account;
  accountOwner?: AccountOwner;
}

function DepositTypeForm({ account, accountOwner }: DepositTypeFormProps) {
  const { user } = useUser();
  const { getAppConstant,addGlobalMessage } = useGlobalContext();
  const [initialized, setInitialized] = React.useState(false as boolean);
  const [depositTypes, setDepositType] = React.useState(
    [] as Array<RadioGroupOption>
  );
  const { values, setFieldValue } = useFormikContext<AccountContribution>();
  const [stateTaxDeductible, setStateTaxDeductible] = useState(
    [] as Array<StateTaxDeadline>
  );

  const getAllStateTaxDeadline = async () => {
    await getStateTaxDeadline(currentTaxYear, user.token, user)
      .then((res) => {
          setStateTaxDeductible(res.data);
      })
      .catch((err) => {
 addGlobalMessage(errorMessages(err) || 'Error fetching state tax deadline'
        );
      });
  };

  useEffect(() => {
    getAllStateTaxDeadline();
  }, [user.token]);

  useEffect(() => {
    if (initialized) {
      setFieldValue('depositType', '');
    }
    setInitialized(true);
  }, [values.depositMethod]);

  useEffect(() => {
    const esaResponsibleIsDepositor =
      values.depositorFirstName === account.responsibleFirstName &&
      values.depositorLastName === account.responsibleLastName;
    const statefilingDeadline: StateTaxDeadline = stateTaxDeductible && stateTaxDeductible.find(
      (item: StateTaxDeadline) =>  item.state=== accountOwner.state );
    const filingDeadline = statefilingDeadline ? statefilingDeadline.filingDeadline : '';
    const promptText = statefilingDeadline ? statefilingDeadline.promptText : '';

    setDepositType(
      getDepositTypes(
        getAppConstant,
        account,
        values,
        esaResponsibleIsDepositor,
        filingDeadline,
        promptText
      )
    );
  }, [
    account.accountType,
    values.depositMethod,
    values.payableTo,
    values.fromAccountType,
    values.depositorFirstName,
    values.depositorLastName,
    account.responsibleFirstName,
    account.responsibleLastName,
    stateTaxDeductible
  ]);

  return (
    <Box maxWidth={640}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Field
            component={SiraRadioField}
            name="depositType"
            options={depositTypes}
          />
        </Grid>
        <DepositTypeFields />
      </Grid>
    </Box>
  );
}

export default DepositTypeForm;
