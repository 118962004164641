import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Typography, Grid, Paper } from '@mui/material';

import { useUser } from '../auth/useUser';
import { useGlobalContext } from '../auth/useGlobalContext';
import AccountSearchForm from '../components/form/accountSearch/AccountSearchForm';
import AccountSearchResults from '../components/form/accountSearch/AccountSearchResults';
import ReviewDocumentForm from '../components/form/newAccount/ReviewDocumentForm';
import Layout from '../components/Layout';
import FormPaper from '../components/FormPaper';
import { AccountOwner } from '../api/AccountOwnerApi.d';
import { getAccount } from '../api/AccountApi';
import TransferSourceForm, {
  TRANSFER_SOURCE_INIT,
} from '../components/form/transfer/TransferSourceForm';
import {
  changeTransferRequestStatus,
  createTransferRequest,
  createTransferRequestDocument,
  getTransferRequest,
  getTransferRequestDocument,
  saveTransferAllocations,
  updateTransferRequest,
} from '../api/TransferApi';
import {
  TransferAllocation,
  TransferRequest,
  TransferRequestStatus,
  TransferRequestStatusState,
  Workflow,
} from '../api/TransferApi.d';
import {
  addTransactionData,
  setSearchResponse,
  setSelectedAccount,
  skipStep,
  completeTransaction,
  rejectTransaction,
  useTransactionReducer,
} from './TransactionReducer';
import TransferSourceOwnerForm, {
  TRANSFER_OWNER_INIT,
} from '../components/form/transfer/TransferSourceOwnerForm';
import TransferInstructionsForm, {
  TRANSFER_INSTRUCTIONS_INIT,
} from '../components/form/transfer/TransferInstructionsForm';
import { getAccountOwner } from '../api/AccountOwnerApi';
import { AccountStatus, TransactionType } from '../api/AccountApi.d';
import TransferAllocationForm, {
  TRANSFER_ALLOCATION_INIT,
} from '../components/form/transfer/TransferAllocationForm';
import TransactionStepper, { TransactionStep } from './TransactionStepper';
import {
  useUnsavedChangesWarning,
  RowDefinition,
} from '../components/useUnsavedChangesWarning';
import SiraAccountOwnerInfo from '../components/form/SiraAccountOwnerInfo';
import { IMMDocumentType } from '../api/ESignApi.d';
import SiraPageAlert from '../components/SiraPageAlert';
import SkipChallenge from '../components/steps/SkipChallenge';
import { errorMessages } from '../utils/errorhandling.utils';

function MakeRolloverTransfer() {
  let isMounted = true;
  const [pageState] = useTransactionReducer();
  const { user } = useUser();
  const { addGlobalMessage,organization } = useGlobalContext();
  const queryParams = new URLSearchParams(useLocation().search);
  const [isLoading, setIsLoading] = useState(false as boolean);
  const [accountOwner, setAccountOwner] = useState({} as AccountOwner);
  const [allocations, setAllocations] = React.useState(
    [] as Array<TransferAllocation>
  );
  const { query = '' } = pageState.searchResponse || {};
  const { accountId = '', accountOwnerId = '' } = pageState.selectedAccount;
  const { transferRequestId, transferRequestStatus } =
    pageState.transferRequestInformation;
  const isAwaitingSignature =
  [
    TransferRequestStatus.signature,
    TransferRequestStatus.review,
  ].includes(transferRequestStatus);
  const isActive = transferRequestStatus === TransferRequestStatus.awaiting;
  const loadingExistingTransferRequest =
    queryParams.get('accountId') && queryParams.get('transferRequestId');
  const { UnsavedChangesPrompt, setUnsavedChanges } =
    useUnsavedChangesWarning();

  // Does this account support a transfer/rollover?

  // Get the full accountOwner for the account loaded
  const updateAccountOwner = async (): Promise<void> => {
    setIsLoading(true);

    await getAccountOwner(accountOwnerId, user.organizationId, user.token, user)
      .then((res) => {
        if (isMounted) {
          setIsLoading(false);
          setAccountOwner(res.data);
        }
      })
      .catch(() => {
        if (isMounted) {
          setIsLoading(false);
        }
      });
  };

  const fetchAndSetAccount = async (): Promise<void> => {
    await getAccount(
      queryParams.get('accountId'),
      queryParams.get('accountOwnerId'),
      user.organizationId,
      user.token,user)
      .then((res) => {
        if (isMounted) {
          setSelectedAccount(res.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {

        if (isMounted) {
          setIsLoading(false);
 addGlobalMessage(errorMessages(err) || 'Could not fetch the preselected account'
          );
        }
      });
  };

  // Lookup distribution and set it with query params passed
  const fetchAndSetTransferRequestInfo = async (): Promise<void> => {
    await getTransferRequest(
      user.organizationId,
      queryParams.get('accountOwnerId'),
      queryParams.get('accountId'),
      queryParams.get('transferRequestId'),
      user.token
    )
      .then((res) => {
        if (isMounted) {
          addTransactionData({ transferRequestInformation: res.data }, false);
          // Skip flow if awaiting signature
          if (
            res.data.transferRequestStatus === TransferRequestStatus.signature
          ) {
            skipStep(5);
          }
          setIsLoading(false);
        }
      })
      .catch((err) => {

        if (isMounted) {
          setIsLoading(false);
 addGlobalMessage(errorMessages(err) || 'Error fetching rollover/transfer request info'
          );
        }
      });
  };

  // Optionaly set account and transfer when passed query params
  async function preselectAccountAndSetTransferRequestInfo(): Promise<void> {
    if (queryParams.get('accountId')) {
      setIsLoading(true);
      await fetchAndSetAccount();
    }
    if (loadingExistingTransferRequest) {
      setIsLoading(true);
      await fetchAndSetTransferRequestInfo();
    }
  }

  // Update the status imperatively after document creation
  // The api does this but doesn't send a payload back so we'll hardcode it here for now
  const handleCreateDocument = () => {
    if (transferRequestStatus === TransferRequestStatus.pending) {
      addTransactionData(
        {
          transferRequestInformation: {
            transferRequestStatus: TransferRequestStatus.signature,
          },
        },
        false
      );
    }
  };

  // Generate the PDF in S3 and/or stream it
  const viewDocument = (): Promise<any> => {
    return isAwaitingSignature || isActive
      ? getTransferRequestDocument(
          user.organizationId,
          accountOwnerId,
          accountId,
          transferRequestId,
          user.token
        )
      : createTransferRequestDocument(
          user.organizationId,
          accountOwnerId,
          accountId,
          transferRequestId,
          user.token
        );
  };

  // Advance the transfer request status to the next
  const progressCurrentTransferRequestStatus = async (
    finalizeWorkflow: boolean
  ): Promise<void> => {
    await changeTransferRequestStatus(
      user.organizationId,
      accountId,
      accountOwnerId,
      transferRequestId,
      TransferRequestStatusState.next,
      {} as Workflow,
      user.token
    )
      .then((res) => {
        if (isMounted) {
          // Set latest status in reducer
          addTransactionData({ transferRequestInformation: res.data }, false);
          if (finalizeWorkflow) {
            completeTransaction();
            setUnsavedChanges(null);
          }
        }
      })
      .catch(() => {
        if (isMounted) {
          rejectTransaction();
        }
      });
  };

  // Revert transaction to pending before saving to allow updates
  const revertToPending = async (): Promise<TransferRequestStatus> => {
    const response = await changeTransferRequestStatus(
      user.organizationId,
      accountId,
      accountOwnerId,
      transferRequestId,
      TransferRequestStatusState.previous,
      {} as Workflow,
      user.token
    );
    const { transferRequestStatus: newTransferRequestStatus } = response.data;

    if (response.errorMessage) {
      addGlobalMessage('Error reverting contribution status');
      return transferRequestStatus;
    }
    if (isMounted) {
      addTransactionData(
        {
          transferRequestInformation: {
            transferRequestStatus: newTransferRequestStatus,
          },
        },
        false
      );
    }

    return newTransferRequestStatus;
  };

  // Update transaction or create if no ID provided (revert if updating AWAITING_SIGNATURE)
  const saveTransferRequest = async (data: TransferRequest) => {
    const merged = data; // Merge a reverted status immediately when saving
    const apiMethod = data.transferRequestId
      ? updateTransferRequest
      : createTransferRequest;

    setIsLoading(true);

    if (isAwaitingSignature) {
      const newTransferRequestStatus = await revertToPending();
      merged.transferRequestStatus = newTransferRequestStatus;
    }

    await apiMethod(
      merged,
      user.organizationId,
      accountOwnerId,
      accountId,
      user.token
    )
      .then((res) => {
        if (isMounted) {
          addTransactionData({ transferRequestInformation: res.data });
          setIsLoading(false);
        }
        const transactionData: RowDefinition = {
          accountId: pageState.selectedAccount.accountId,
          accountOwnerId: pageState.selectedAccount.accountOwnerId,
          transactionType: TransactionType.transfer,
          transferRequestId: res.data.transferRequestId,
        };
        setUnsavedChanges(transactionData);
      })
      .catch((err) => {

        if (isMounted) {
          setIsLoading(false);
 addGlobalMessage(errorMessages(err) || 'Error saving transfer request'
          );
        }
      });
  };

  // Send then store latest allocations
  const updateAndSaveAllocations = async (
    data: Array<TransferAllocation>
  ): Promise<void> => {
    setIsLoading(true);

    if (isAwaitingSignature) {
      await revertToPending();
    }

    const response = await saveTransferAllocations(
      data,
      user.organizationId,
      accountOwnerId,
      accountId,
      transferRequestId,
      user.token
    );

    if (isMounted) {
      if (response.errorMessage) {
        setIsLoading(false);
        addGlobalMessage(
          response.errorMessage || 'Error saving transfer request allocations'
        );
      } else {
        setAllocations(response.data);
        skipStep(5);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (user.token || user.organizationId) {
      preselectAccountAndSetTransferRequestInfo();
    }

    return () => {
      isMounted = false;
    };
  }, [user.token, user.organizationId]);

  useEffect(() => {
    setIsLoading(true);
    updateAccountOwner();
  }, [accountOwnerId]);

  const makeRolloverTransferSteps: Array<TransactionStep> = [
    {
      label: 'Find Owner and Account',
      stepContent: (
        <Box width="1" mt={4} mb={4}>
          <Grid container>
            <Grid item xs={12} md={8}>
              <Box mt={2} mb={4}>
                <AccountSearchForm setResponse={setSearchResponse} />
              </Box>
            </Grid>
          </Grid>
          {query && (
            <AccountSearchResults
              filterFunction={({
                account: {
                  accountStatus: statusMatcher = '' as AccountStatus,
                } = {},
              }) =>
                [
                  AccountStatus.open,
                  AccountStatus.closed,
                  AccountStatus.review,
                  AccountStatus.signature,
                ].includes(statusMatcher)
              }
              response={pageState.searchResponse}
              onResultClick={setSelectedAccount}
            />
          )}
        </Box>
      ),
    },
    {
      label: 'Select Source of Assets',
      stepContent: (
        <Box mt={5} mb={3}>
          <TransferSourceForm
            account={pageState.selectedAccount}
            accountOwner={accountOwner}
            onSubmit={(transferRequestInformation) => {
              addTransactionData({ transferRequestInformation });
            }}
            initialValues={{
              ...TRANSFER_SOURCE_INIT,
              ...pageState.transferRequestInformation,
            }}
          />
        </Box>
      ),
    },
    {
      label: 'Enter Current Account Owner Information',
      stepContent: (
        <Box mt={5} mb={3}>
          <TransferSourceOwnerForm
            accountOwner={accountOwner}
            sourceAccountType={
              pageState.transferRequestInformation.sourceAccountType
            }
            destinationAccountType={pageState.selectedAccount.accountType}
            onSubmit={(transferRequestInformation) => {
              addTransactionData({ transferRequestInformation });
            }}
            initialValues={{
              ...TRANSFER_OWNER_INIT,
              ...pageState.transferRequestInformation,
            }}
          />
        </Box>
      ),
    },
    {
      label: 'Provide Rollover/Transfer Instructions',
      stepContent: (
        <Box mt={5} mb={3}>
          <TransferInstructionsForm
            setAllocations={setAllocations}
            initialValues={{
              ...TRANSFER_INSTRUCTIONS_INIT,
              ...pageState.transferRequestInformation,
            }}
            onSubmit={saveTransferRequest}
          />
        </Box>
      ),
    },
    {
      label: 'Select Investment Options',
      stepContent: (
        <SkipChallenge
        onSkip={() => {
          skipStep(pageState.activeStep + 1);
        }}
        skipText="Skip"
        acceptText="ALLOCATE ASSETS"
      >
        <TransferAllocationForm
          setAllocations={setAllocations}
          allocations={[...TRANSFER_ALLOCATION_INIT, ...allocations]}
          transferRequestInformation={pageState.transferRequestInformation}
          onSubmit={updateAndSaveAllocations}
        />
        </SkipChallenge>
      ),
    },
    {
      label: 'Review and Sign Document',
      stepContent: (
        <>
          <ReviewDocumentForm
            searchResult={pageState.selectedAccount}
            allowSignature={false}
            submitName="Done"
            hideStepButtonBar={pageState.completed}
            getDocument={viewDocument}
            onGetDocument={handleCreateDocument}
            onSubmit={async () => {
              if (isAwaitingSignature) {
                await progressCurrentTransferRequestStatus(true);
              }
            }}
            documentType={IMMDocumentType.transfer}
            transactionName="Rollover/Transfer"
          />
          {isActive && (pageState.completed || pageState.rejected) && (
            <SiraPageAlert
              pageStatus={pageState.rejected}
              transactionName="Rollover/Transfer"
              accountId={accountId}
              accountOwnerId={accountOwnerId}
            />
          )}
        </>
      ),
    },
  ];

  return (
    <Layout>
      <FormPaper>
        <>
          <Typography variant="overline" gutterBottom>
            Transactions
          </Typography>

          <Typography color="secondary" variant="h1" gutterBottom>
            Request Rollover/Transfer
          </Typography>

          <Typography variant="subtitle1" gutterBottom>
            Find the account owner and select the correct account into which the
            rollover or transfer will be requested.
          </Typography>
          {accountId && (
            <Box mt={2}>
              <Paper elevation={3}>
                <SiraAccountOwnerInfo
                  selectedAccount={pageState.selectedAccount}
                  accountOwner={accountOwner}
                />
              </Paper>
            </Box>
          )}

          <Box mt={5}>
            <TransactionStepper
              steps={
                organization.displayInvestments
                  ? makeRolloverTransferSteps
                  : makeRolloverTransferSteps.filter(
                      (step) => step.label !== 'Select Investment Options'
                    )
                }
              activeStep={pageState.activeStep}
              isLoading={isLoading}
              onStepClick={(index) => {
                if (
                  !loadingExistingTransferRequest ||
                  (loadingExistingTransferRequest && index > 0)
                ) {
                  skipStep(index);
                }
              }}
            />
          </Box>
          {UnsavedChangesPrompt}
        </>
      </FormPaper>
    </Layout>
  );
}

export default MakeRolloverTransfer;
