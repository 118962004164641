import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { Grid, Box, IconButton, Button } from '@mui/material';
import { getYear, parseISO } from 'date-fns';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid';
import { useUser } from '../../auth/useUser';
import { useGlobalContext } from '../../auth/useGlobalContext';
import {
  createOrSaveFairMarketValue,
  deleteFairMarketValue,
  getFairMarketValues,
} from '../../api/FairMarketValueApi';
import {
  FairMarketValue,
  FmvSearchResponseObject,
} from '../../api/FairMarketValueApi.d';
import FmvForm, { FMV_INIT } from './FmvForm';
import {
  currentTaxYear,
  currentYear,
  determineAgeGroup,
} from '../../app.constants';
import { currencyValueFormatter } from '../../utils/DataGrid.utils';
import SiraNoRowsOverlay from '../SiraNoRowsOverlay';
import { Account, AccountType } from '../../api/AccountApi.d';
import { createOrSaveAccount } from '../../api/AccountApi';
import { AccountOwner } from '../../api/AccountOwnerApi.d';
import { getLifeExpectancyTerms } from '../../api/LifeExpectancyApi';
import { getAccountDistributions } from '../../api/DistributionApi';
import {
  DistributionStatus,
  DistributionReason,
} from '../../api/DistributionApi.d';
import { UserRole } from '../../api/UserApi.d';
import { BeneficiaryTypes } from '../../api/BeneficiariesApi.d';
import { errorMessages } from '../../utils/errorhandling.utils';

interface AccountOwnerAccountFmvProps {
  account: Account;
  accountOwner: AccountOwner;
}

interface AccountFmvRow extends FairMarketValue {
  dateOfDeathAccountBalance?: string;
  rmd?: string;
  term?: number;
  lifeExpectancyTable?: string;
  dateofDeath?: string;
}

export const POSITIVE_DOLLAR_SCHEMA = {
  taxYearFilters: yup.array(yup.number()).label('Fair Market Value'),
};

function AccountOwnerAccountFmv(props: AccountOwnerAccountFmvProps) {
  let isMounted = true;
  const { account, accountOwner } = props;
  const [fmvToEdit, setFmvToEdit] = useState({} as AccountFmvRow);
  const [isUpdate, setIsUpdate] = useState(true as boolean);
  const [isLoading, setIsLoading] = useState(false as boolean);
  const [showForm, setShowForm] = useState(false as boolean);
  const [fmvs, setFmvs] = useState([] as Array<AccountFmvRow>);
  const [fmvRecord, setFmvRecord] = useState({} as FmvSearchResponseObject);
  const [hasDateOfDeathReason, setHasDateOfDeathReason] = useState(
    false as boolean
  );
  const { user } = useUser();
  const { addGlobalMessage } = useGlobalContext();
  const { accountId, accountOwnerId, accountType } = account;
  const { dateOfBirth, dateOfDeath } = accountOwner;
  const { token, organizationId } = user;
  const [hasMatchingRoles, setHasMatchingRoles] = useState(
    (user.roles.includes(UserRole.orgTransactions) &&
      !user.roles.includes(UserRole.orgTransactionsAdmin)) as boolean
  );

  // List of years w/FMV for client validation against duplicate entries
  const existingYears = fmvs.map((item) => item.taxYear);

  // Fetch and attach some current tax year's RMD data to that year's FMV entry
  async function getAndAttachRMDForCurrentTaxYear(
    fmvRows: Array<AccountFmvRow>
  ): Promise<void> {
    // Look for and only load up RMD if there's a current tax year FMV entry
    const { fairMarketValue: currentTaxYearFMV = '' } =
      fmvRows.find(({ taxYear }) => Number(taxYear) === currentTaxYear) || {};

    // If there is, get the RMD info for this account using the relevant FMV
    if (currentTaxYearFMV) {
      await getLifeExpectancyTerms(
        organizationId,
        accountId,
        accountOwnerId,
        null,
        null, // We don't have to pass in the FMV fromt he client though as the endpoint will use the current one on record
        token,
        user
      ).then(({ data: lifeExpectancy }) => {
        const { uniformLifetimeRMD = {}, jointLifeExpectancyRMD = {} } =
          lifeExpectancy; // We'll only be getting ULE for now
        const { currently73Years, over73, turning73ThisYear } =
          determineAgeGroup(dateOfBirth);
        const isIRA = [
          AccountType.traditionalIra,
          AccountType.traditionalSep,
          AccountType.simpleIra,
        ].includes(accountType);

        const isInherited = [
          AccountType.inheritedTraditional,
          AccountType.inheritedRoth,
        ].includes(accountType);

        // Add only when IRA account and owner 73 or older and an RMD election with the current FMV is made
        if (
          uniformLifetimeRMD &&
          isIRA &&
          (currently73Years || over73 || turning73ThisYear)
        ) {
          const { rmd: uleRMD, distributionPeriod } = uniformLifetimeRMD;
          const { rmd: jleRMD, lifeExpectancyFactor } = jointLifeExpectancyRMD;

          // Merge the RMD data into the row for the current tax year
          const mergedFmvs: Array<AccountFmvRow> = fmvRows.map((row) =>
            Number(row.taxYear) === currentTaxYear
              ? {
                  ...row,
                  rmd: jleRMD || uleRMD, // Prefer JLE first
                  term: lifeExpectancyFactor || distributionPeriod,
                  lifeExpectancyTable: jleRMD
                    ? 'Joint Life Expectancy'
                    : 'Uniform Life Expectancy',
                }
              : row
          );

          setFmvs(mergedFmvs);
        } else if (isInherited) {
          const {
            account,
            singleLifeExpectancyRMD,
            singleLifeExpectancyNonRecalculatedRMD,
          } = lifeExpectancy;
          if (
            account.eligibleDesignatedBeneficiary &&
            account.relationship === BeneficiaryTypes.SPOUSE
          ) {
            const mergedFmvs: Array<AccountFmvRow> = fmvRows.map((row) =>
              Number(row.taxYear) === currentTaxYear
                ? {
                    ...row,
                    rmd: singleLifeExpectancyRMD.rmd, // Prefer JLE first
                    term: singleLifeExpectancyRMD.lifeExpectancy,
                    lifeExpectancyTable: 'Single Life Expectancy',
                  }
                : row
            );
            setFmvs(mergedFmvs);
          } else if (
            account.eligibleDesignatedBeneficiary &&
            account.relationship !== BeneficiaryTypes.SPOUSE
          ) {
            const mergedFmvs: Array<AccountFmvRow> = fmvRows.map((row) =>
              Number(row.taxYear) === currentTaxYear
                ? {
                    ...row,
                    rmd: singleLifeExpectancyNonRecalculatedRMD.rmd, // Prefer JLE first
                    term: singleLifeExpectancyNonRecalculatedRMD.lifeExpectancy,
                    lifeExpectancyTable: 'Single Life Expectancy',
                  }
                : row
            );

            setFmvs(mergedFmvs);
          } else {
            setFmvs(fmvRows);
          }
        } else {
          setFmvs(fmvRows);
        }
      });
    } else {
      setFmvs(fmvRows);
    }

    setIsLoading(false);
  }

  async function getActiveDistributions() {
    setIsLoading(true);

    await getAccountDistributions(
      accountId,
      accountOwnerId,
      user.organizationId,
      user.token,
      user,
      [DistributionStatus.active]
    )
      .then((res) => {
        if (isMounted) {
          const displayToggle =
            res.data.filter((t) =>
              [
                DistributionReason.HSA_DEATH_DIST_SPOUSE,
                DistributionReason.HSA_DEATH_DIST_NON_SPOUSE,
                DistributionReason.HSA_DEATH_DIST_ESTATE,
                DistributionReason.HSA_DEATH_DIST_OTHER,
              ].includes(t.distributionReason)
            ).length > 0;

          setHasDateOfDeathReason(displayToggle);
        }
      })
      .catch((err) => {
        if (isMounted) {
  
          setHasDateOfDeathReason(false);
          addGlobalMessage(errorMessages(err) || `Error fetching account distributions`
          );
        }
      });

    if (isMounted) {
      setIsLoading(false);
    }
  }

  // Get all of the selected account's FMV data and store it
  async function getAndSetFMVData(): Promise<void> {
    setIsLoading(true);

    await getFairMarketValues(
      organizationId,
      accountId,
      accountOwnerId,
      token,
      {},
      user
    )
      .then(({ data = {} }) => {
        const { fairMarketValues = [], dateOfDeathAccountBalance = '' } = data;

        const yearOfDeath = String(getYear(parseISO(dateOfDeath)));

        // Morph the DOD & account balance info into a row
        const dateOfDeathFmvRow: AccountFmvRow = {
          dateOfDeathAccountBalance,
          taxYear: yearOfDeath,
        };

        if (isMounted) {
          setFmvRecord(data);
          getActiveDistributions();

          getAndAttachRMDForCurrentTaxYear(
            // If a date of death account balance exists, shove it in as the first row
            dateOfDeathAccountBalance
              ? [dateOfDeathFmvRow, ...fairMarketValues]
              : fairMarketValues
          );
        }
      })
      .catch((err) => {

        if (isMounted) {
          addGlobalMessage(errorMessages(err) || 'Error fetching fair market values'
          );
        }
      });
  }

  // Remove the selected fmv entry and refetch the latest
  async function removeFmv(fmv: AccountFmvRow): Promise<void> {
    const { dateOfDeathAccountBalance } = fmv;

    // Zero the account DOD balance if removing it, otherwise use the normal FMV API method
    const promise = dateOfDeathAccountBalance
      ? createOrSaveAccount(
          {
            ...account,
            dateOfDeathAccountBalance: '0',
          },
          organizationId,
          accountOwnerId,
          token,
          user
        )
      : deleteFairMarketValue(
          fmv,
          organizationId,
          accountId,
          accountOwnerId,
          token,
          user
        );

    await promise
      .then(() => {
        getAndSetFMVData();
        addGlobalMessage('FMV record successfully removed', {
          severity: 'success',
        });
      })
      .catch((err) => {

        if (isMounted) {
          addGlobalMessage(errorMessages(err) || 'Error removing fair market value'
          );
        }
      });
  }

  // Call the combo api method and update or add based on whether an FMV id is present
  async function addOrChangeFmv(fmv: AccountFmvRow): Promise<void> {
    const { dateOfDeathAccountBalance, fairMarketValueId, hsaYearOfDeath } =
      fmv;
    // Change the account DOD balance if changing/adding it, otherwise use the normal FMV API method
    const promise = dateOfDeathAccountBalance
      ? createOrSaveAccount(
          {
            ...account,
            dateOfDeathAccountBalance,
            hsaYearOfDeath,
          },
          organizationId,
          accountOwnerId,
          token,
          user
        )
      : createOrSaveFairMarketValue(
          fmv,
          organizationId,
          accountId,
          accountOwnerId,
          token,
          user
        );

    await promise
      .then(() => {
        if (isMounted) {
          if (!fairMarketValueId) {
            setIsUpdate(true);
          }
          setShowForm(false);
          getAndSetFMVData();
        }
      })
      .catch((err) => {

        if (isMounted) {
          addGlobalMessage(errorMessages(err) || 'Error changing fair market values'
          );
        }
      });
  }

  const columns = [
    {
      field: 'action',
      headerName: '',
      sortable: false,
      hide: hasMatchingRoles,
      renderCell: (params: GridCellParams) => {
        const { row = {} } = params;

        return (
          <>
            <Grid container justifyContent="flex-end">
              <IconButton
                data-testid="EditFmv"
                size="small"
                aria-label="Edit Fair Market Value"
                onClick={() => {
                  setFmvToEdit(row);
                  setShowForm(true);
                }}
                disabled={row.taxYear < currentYear - 2}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                data-testid="DeleteFmv"
                size="small"
                aria-label="Delete Fair Market Value"
                onClick={async () => {
                  await removeFmv(row);
                }}
                disabled={row.taxYear < currentYear - 2 || row.newFmv}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          </>
        );
      },
    },
    {
      field: 'taxYear',
      headerName: 'Tax Year',
      width: 100,
      renderCell: (params: GridCellParams) => {
        const { value = '' } = params;
        if (value === 'NaN') {
          return '-';
        }
        return value !== '' || value !== undefined ? value : '-';
      },
    },
    {
      field: 'fairMarketValue',
      headerName: '12/31 FMV',
      sortable: false,
      width: 152,
      valueFormatter: currencyValueFormatter,
    },
    {
      field: 'dateOfDeathAccountBalance',
      headerName: 'Date of Death FMV',
      width: 156,
      valueFormatter: currencyValueFormatter,
    },
    {
      field: 'rmd',
      headerName: 'RMD Amount',
      width: 156,
      valueFormatter: currencyValueFormatter,
    },
    {
      field: 'term',
      headerName: 'Term',
      width: 128,
      valueFormatter: ({ value = '' }) => (value ? `${value} years` : '-'),
    },
    {
      field: 'lifeExpectancyTable',
      headerName: 'Type',
      width: 200,
    },
  ] as GridColDef[];

  useEffect(() => {
    if (user.roles && accountId) {
      getAndSetFMVData();
      setHasMatchingRoles(
        user.roles.includes(UserRole.orgTransactions) &&
          !user.roles.includes(UserRole.orgTransactionsAdmin)
      );
    }

    return () => {
      isMounted = false;
    };
  }, [user.roles, accountId]);

  return showForm ? (
    <FmvForm
      fmvRecord={fmvRecord}
      initialValues={{
        ...FMV_INIT,
        ...fmvToEdit,
      }}
      existingYears={existingYears}
      onSubmit={async (data) => {
        await addOrChangeFmv(data);
      }}
      onCancel={() => {
        setShowForm(false);
        setIsUpdate(true);
      }}
      isUpdating={isUpdate}
      showDateOfDeath={hasDateOfDeathReason}
      fmvTaxYear={fmvToEdit.taxYear}
    />
  ) : (
    <Box width="1">
      <Box>
        <DataGrid
          sx={{
            filter: isLoading ? 'blur(5px)' : '',
            transition: 'filter 0.33s ease-in-out',
          }}
          components={{
            NoRowsOverlay: SiraNoRowsOverlay,
          }}
          initialState={{
            columns: {
              columnVisibilityModel: {
                // Hide columns based on user role
                action: !hasMatchingRoles,
              },
            },
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          pageSizeOptions={[10]}
          disableColumnMenu
          autoHeight
          columns={columns}
          rows={fmvs.map((row, id) => ({ ...row, id }))}
        />
      </Box>
      {!hasMatchingRoles && (
        <Box mt={2}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => {
              setIsUpdate(false);
              setFmvToEdit({} as AccountFmvRow);
              setShowForm(true);
            }}
          >
            Add FMV
          </Button>
        </Box>
      )}
    </Box>
  );
}

export default AccountOwnerAccountFmv;
