import React, { useEffect, useState } from 'react';
import { useParams, Params } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import {
  Paper,
  Box,
  Typography,
  Grid,
  Link,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  LinearProgress,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { SiraUser, useUser } from '../auth/useUser';
import {
  createOrSaveAccountOwner,
  getCustomerPortalAccountOwner,
} from '../api/AccountOwnerApi';
import { AccountOwner, AccountOwnerResponse } from '../api/AccountOwnerApi.d';
import Layout from '../components/Layout';
import PaperlessPage from '../components/PaperlessPage';
import ObscuredTaxId, { TaxIdFormat } from '../components/ObscuredTaxId';
import { useGlobalContext } from '../auth/useGlobalContext';
import AccountOwnerForm, {
  ACCOUNT_OWNER_INIT,
} from '../components/form/newAccount/AccountOwnerForm';
import OwnerAccounts from '../components/ownerProfile/OwnersAccounts';
import Loading from '../components/Loading';
import { getCustomerPortalUser } from '../api/UserApi';
import { errorMessages } from '../utils/errorhandling.utils';

export interface AccountOwnerPageParams extends Params {
  accountOwnerId: string;
  accountId: string;
}

export interface AccountOwnerScreenProps {
  userInfo: SiraUser;
}

function AccountOwnerAdditionalInfo(props) {
  const [isEditing, setIsEditing] = useState(false as boolean);
  const [isLoading, setIsLoading] = useState(false as boolean);

  const { addGlobalMessage, countries } = useGlobalContext();
  const { user } = useUser();

  const { accountOwner = {}, setResponse } = props;
  const {
    representativeFullName = '',
    dateOfBirth = '',
    dateOfDeath = '',
    emailAddress = '',
    phoneNumber = '',
    addressLine1 = '',
    addressLine2 = '',
    addressLine3 = '',
    city = '',
    state = '',
    foreignStateProvinceRegion = '',
    zip = '',
    country = '',
  } = accountOwner as AccountOwner;

  const saveAccountOwner = async (data: AccountOwner) => {
    setIsLoading(true);
    await createOrSaveAccountOwner(data, user.organizationId, user.token, user)
      .then((res) => {
        setResponse(res);
        setIsEditing(false);
        setIsLoading(false);
      })
      .catch((err) => {

 addGlobalMessage(errorMessages(err) || 'Error saving account owner information'
        );
      });
  };

  if (isLoading) {
    return (
      <Box width="1" mt={5} mb={3}>
        <LinearProgress color="secondary" />
      </Box>
    );
  }

  return isEditing ? (
    <>
      <AccountOwnerForm
        initialValues={{
          ...ACCOUNT_OWNER_INIT,
          ...accountOwner,
        }}
        onSubmit={saveAccountOwner}
        onCancel={() => {
          setIsEditing(false);
        }}
        showDateOfDeath
      />
    </>
  ) : (
    <>
      <Grid container spacing={3}>
        {representativeFullName && (
          <Grid item xs={12}>
            <Typography variant="h6">Representative</Typography>
            <Typography variant="body1">{representativeFullName}</Typography>
          </Grid>
        )}

        <Grid item xs={12} md={6}>
          <Typography variant="h6">Date of Birth</Typography>
          <Typography variant="body1">
            {dateOfBirth && format(parseISO(String(dateOfBirth)), 'MM/dd/yyyy')}
          </Typography>
        </Grid>
        {dateOfDeath && (
          <Grid item xs={12} md={6}>
            <Typography variant="h6">Date of Death</Typography>
            <Typography variant="body1">
              {format(parseISO(String(dateOfDeath)), 'MM/dd/yyyy')}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <Typography variant="h6">Email</Typography>
          <Typography variant="body1">{emailAddress || '---'}</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6">Phone</Typography>
          <Typography variant="body1">{phoneNumber || '---'}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">Address</Typography>
          <Typography variant="body1">{addressLine1}</Typography>
          <Typography variant="body1">{addressLine2}</Typography>
          <Typography variant="body1">{addressLine3}</Typography>
          {city && (
            <Typography variant="body1">
              {city}
              {city && ','}
              {foreignStateProvinceRegion || (state !== 'XX' && state)} {zip}
            </Typography>
          )}
          <Typography variant="body1">{countries[country]}</Typography>
        </Grid>
      </Grid>
    </>
  );
}

function AccountOwnerScreen(props: AccountOwnerScreenProps) {
  let isMounted = true;
  // eslint-disable-next-line react/destructuring-assignment
  const { userInfo } = props;

  const { addGlobalMessage, customerPortalId } = useGlobalContext();
  const { accountId = '' } = useParams() as AccountOwnerPageParams;
  const [response, setResponse] = useState({} as AccountOwnerResponse);
  const { data: accountOwner = {} } = response;
  const {
    name = '',
    fullName = '',
    emailAddress = '',
    taxpayerIdNumber = '',
  } = accountOwner;
  const [isLoading, setIsLoading] = useState(false as boolean);
  const [accountOwnerId, setAccountOwnerId] = useState('');

  // retrieve user information from auth0
  async function checkIfUserExists() {
    // if user exists, we will get the user information and set the user information in the global context
    // if user does not exist, we will call the account verification form to have the user enter additional information
    await getCustomerPortalUser(userInfo.organizationId, userInfo.token).then(
      (res) => {
        if (res.data) {
          setAccountOwnerId(res.data.customerPortalId);
        }
      }
    );
  }

  async function getSelectedAccountOwner(): Promise<void> {
    let id = customerPortalId;
    if (accountOwnerId) {
      id = accountOwnerId;
    }
    getCustomerPortalAccountOwner(id, userInfo.organizationId, userInfo.token)
      .then((res) => {
        setIsLoading(false);
        if (isMounted) {
          setResponse(res);
        }
      })
      .catch((err) => {
        const { response: { data = {} } = {} } = err;
        if (isMounted) {
          setResponse({
            data: {},
            errorMessage: data.message,
          });
          addGlobalMessage('Error fetching account owner profile');
        }
      });
  }

  useEffect(() => {
    setIsLoading(true);

    if (userInfo.roles) {
      checkIfUserExists();
    }
    return () => {
      isMounted = false;
    };
  }, [userInfo.roles]);

  // running account call when account owner id changes
  useEffect(() => {
    if (accountOwnerId) {
      getSelectedAccountOwner();
    }
  }, [accountOwnerId]);

  return isLoading ? (
    <Loading />
  ) : (
    <Layout>
      <PaperlessPage>
        <>
          <Typography variant="overline" gutterBottom>
            Account Owner Profile
          </Typography>
          <Paper elevation={3}>
            <Box mt={2} p={4}>
              <Typography variant="overline">Name</Typography>

              <Typography color="secondary" variant="h3" gutterBottom>
                {fullName}
              </Typography>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2" gutterBottom>
                    Email
                  </Typography>

                  <Link href={`mailto:${emailAddress}`}>
                    <Typography color="primary">{emailAddress}</Typography>
                  </Link>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2" gutterBottom>
                    SSN/ID
                  </Typography>

                  <Typography variant="body1">
                    <ObscuredTaxId
                      taxId={taxpayerIdNumber}
                      format={name ? TaxIdFormat.ein : TaxIdFormat.ssn}
                    />
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            <Accordion sx={{ p: 2 }} elevation={0}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="body2">More Information</Typography>
              </AccordionSummary>

              <AccordionDetails>
                <AccountOwnerAdditionalInfo
                  setResponse={setResponse}
                  accountOwner={response.data}
                />
              </AccordionDetails>
            </Accordion>
          </Paper>

          <OwnerAccounts
            accountOwnerID={accountOwnerId}
            accountOwner={accountOwner}
            accountIdToSelect={accountId}
            userInfo={userInfo}
          />
        </>
      </PaperlessPage>
    </Layout>
  );
}

export default AccountOwnerScreen;
