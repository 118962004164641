import React, { useEffect, useState } from 'react';
import { FieldArray, Form, Formik, ErrorMessage } from 'formik';
import NumberFormat from 'react-number-format';
import * as yup from 'yup';
import {
  useTheme,
  Alert,
  Grid,
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  LinearProgress,
} from '@mui/material';

import moneyFormatter from '../../../moneyFormatter';
import { useUser } from '../../../auth/useUser';
import {
  getInvestmentRates,
  getSymitarInvestmentRates,
} from '../../../api/InvestmentRateApi';
import { InvestmentRate, Allocation } from '../../../api/InvestmentRateApi.d';
import StepButtonBar from '../../steps/StepButtonBar';
import AllocationPieDisplay from './AllocationPieDisplay';
import SiraCurrencyField from '../SiraCurrencyField';
import { useGlobalContext } from '../../../auth/useGlobalContext';
import { AccountContribution } from '../../../api/ContributionApi.d';
import SiraTextField from '../SiraTextField';
import { roundNumber } from '../../../utils/App.utils';
import { all } from 'cypress/types/bluebird';

export interface ContributionAllocationFormData {
  allocations: Array<Allocation>;
}

export interface ContributionAllocationFormProps {
  existingAllocations: Array<Allocation>;
  contributionInfo: AccountContribution;
  onSubmit?: Function;
  onReset?: Function;
  submitName?: string;
  resetName?: string;
  accountOwnerId?: string;
}

export const ALLOCATION_INIT: Array<Allocation> = [];

function ContributionAllocationForm({
  existingAllocations = [],
  contributionInfo,
  onSubmit = () => {},
  onReset = () => {},
  submitName,
  resetName,
  accountOwnerId,
}: ContributionAllocationFormProps) {
  let isMounted = true;
  const theme = useTheme();
  const [allocations, setAllocations] = useState(existingAllocations);
  const [investmentRates, setInvestmentRates] = useState(
    [] as Array<InvestmentRate>,
  );
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useUser();
  const { organization, addGlobalMessage } = useGlobalContext();
  const { amount = 0 } = contributionInfo;

  const classes = {
    tableLabel: {
      marginBottom: '1.5rem',
    },
    tableCell: {
      borderBottom: 'none',
      borderRight: '1px solid grey',
    },
    headCell: {
      paddingTop: 0,
      borderBottom: 'none',
      borderRight: '1px solid grey',
      color: theme.palette.text.primary,
      fontWeight: 'bold',
    },
    lastTableCell: {
      borderBottom: 'none',
    },
    lastHeadCell: {
      paddingTop: 0,
      borderBottom: 'none',
      color: theme.palette.text.primary,
      fontWeight: 'bold',
    },
  };

  const CONTRIBUTION_ALLOCATION_SCHEMA = yup.object().shape({
    allocations: yup
      .array()
      .of(
        yup.object().shape({
          amount: yup.string(),
          investmentNumber: yup.string(),
        }),
      )
      .required()
      .test(
        'total',
        `The sum of all allocation amounts must equal $${amount}`,
        (allocs = []) => {
          const totalAlloc = allocs.reduce((total, allocation) => {
            return roundNumber(total) + (Number(allocation.amount) || 0);
          }, 0);

          return totalAlloc === amount;
        },
      ),
  });

  // Trims 0 contribution allocations and adds fields that aren't in the form data
  function contributionAllocationsFactory(
    formAllocations: Array<Allocation>,
  ): Array<Allocation> {
    return investmentRates.reduce(
      (acc: Array<Allocation>, rate: InvestmentRate, index) => {
        const { amount: allocationAmount = '', investmentNumber = '' } =
          formAllocations[index] || {};
        const { investmentRateId = '' } = rate || {};

        if (investmentNumber || allocationAmount) {
          acc.push({
            amount: allocationAmount,
            investmentNumber,
            investmentRateId,
          } as Allocation);
        }

        return acc;
      },
      [],
    );
  }

  // Maps incoming allocations to their respective investment rate
  function mapSymitarInitialAllocations(
    rates: Array<InvestmentRate>,
  ): Array<Allocation> {
    return rates.map((rate) => {
      const { amount: allocationAmount = 0, investmentNumber = rate.id } =
        allocations.find(({ id }) => rate.id === id) || {};

      return {
        amount: allocationAmount,
        investmentNumber,
        investmentRateId: rate.id,
        ...rate,
      } as Allocation;
    });
  }

  // Maps incoming allocations to their respective investment rate
  function mapInitialAllocations(
    rates: Array<InvestmentRate>,
  ): Array<Allocation> {
    return rates.map((rate) => {
      const { amount: allocationAmount = 0, investmentNumber = '' } =
        allocations.find(
          ({ investmentRateId }) => rate.investmentRateId === investmentRateId,
        ) || {};

      return {
        amount: allocationAmount,
        investmentNumber,
        investmentRateId: rate.investmentRateId,
      } as Allocation;
    });
  }

  async function getInitialInvestmentRates(): Promise<void> {
    if (user.token && user.organizationId) {
      setIsLoading(true);
      console.log('account Owner id', accountOwnerId);

      if (organization.coreIntegration === 'SYMITAR') {
        await getSymitarInvestmentRates(
          user.organizationId,
          user.token,
          {},
          user,
          accountOwnerId,
        )
          .then((resp) => {
            const initialAllocations = mapSymitarInitialAllocations(resp.data);
            console.log('initialAllocations', initialAllocations);
            if (isMounted) {
              setInvestmentRates(resp.data);
              setAllocations(initialAllocations);
              setIsLoading(false);
            }
          })
          .catch((err) => {
            const { response: { data = {} } = {} } = err;

            setIsLoading(false);
            addGlobalMessage(data.message);
          });
      } else {
        await getInvestmentRates(
          user.organizationId,
          user.token,
          {
            active: true,
          },
          user,
        )
          .then((resp) => {
            const initialAllocations = mapInitialAllocations(resp.data);
            if (isMounted) {
              setInvestmentRates(resp.data);
              setAllocations(initialAllocations);
              setIsLoading(false);
            }
          })
          .catch((err) => {
            const { response: { data = {} } = {} } = err;

            setIsLoading(false);
            addGlobalMessage(data.message);
          });
      }
    }
  }

  useEffect(() => {
    getInitialInvestmentRates();

    return () => {
      isMounted = false;
    };
  }, [user.token, user.organizationId]);

  if (isLoading) {
    return (
      <Box width="1" mt={5} mb={3}>
        <LinearProgress color="secondary" />
      </Box>
    );
  }

  const investmentRows =
    Boolean(investmentRates.length) &&
    allocations.map((investment: Allocation, index: number) => {
      return (
        <TableRow key={investmentRates[index].description}>
          <TableCell sx={classes.tableCell}>
            {investmentRates[index].description}
          </TableCell>
          <TableCell sx={classes.tableCell}>
            {moneyFormatter.format(
              investmentRates[index].minimumInvestment ||
                investmentRates[index].minimumDeposit,
            )}
          </TableCell>
          <TableCell sx={classes.tableCell}>
            <NumberFormat
              displayType="text"
              fixedDecimalScale
              decimalScale={organization.investmentRatePrecision}
              isNumericString
              value={
                investmentRates[index].interestRate ||
                investmentRates[index].divRate
              }
              suffix="%"
            />
          </TableCell>
          {investmentRates[index].apy && (
            <TableCell sx={classes.tableCell}>
              <NumberFormat
                displayType="text"
                fixedDecimalScale
                decimalScale={organization.investmentRatePrecision}
                isNumericString
                value={
                  investmentRates[index].apy
                }
                suffix="%"
              />
            </TableCell>
          )}
          {investmentRates[index].balance && (
            <TableCell sx={classes.tableCell}>
              {moneyFormatter.format(
                investmentRates[index].balance,
              )}
            </TableCell>
          )}
          <TableCell sx={classes.tableCell}>
            <SiraTextField name={`allocations[${index}.investmentNumber]`} />
          </TableCell>
          <TableCell sx={classes.lastTableCell}>
            <SiraCurrencyField name={`allocations[${index}.amount]`} />
          </TableCell>
        </TableRow>
      );
    });

  return (
    <Formik
      initialValues={{ allocations }}
      onSubmit={async (values) => {
        await onSubmit(contributionAllocationsFactory(values.allocations));
      }}
      enableReinitialize
      onReset={() => onReset()}
      validationSchema={CONTRIBUTION_ALLOCATION_SCHEMA}
    >
      {({ isSubmitting }) => {
        
         return (<Form>
          {investmentRates.length > 0 && (
            <AllocationPieDisplay
              investmentRates={investmentRates}
              amount={amount}
            />
          )}

          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="subtitle1" sx={classes.tableLabel}>
                  Use these investment options to allocate full contribution
                </Typography>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <FieldArray name="investments">
                        {() => (
                          <TableRow>
                            <TableCell sx={classes.headCell}>
                              Account Type
                            </TableCell>
                            <TableCell sx={classes.headCell}>Min</TableCell>
                            <TableCell sx={classes.headCell}>Rate</TableCell>
                            <TableCell sx={classes.headCell}>{organization.coreIntegration === 'SYMITAR' ? 'Balance' :'APY'}</TableCell>
                            <TableCell sx={classes.headCell}>
                              Investment Number
                            </TableCell>
                            <TableCell sx={classes.lastHeadCell}>
                              Amount
                            </TableCell>
                          </TableRow>
                        )}
                      </FieldArray>
                    </TableHead>
                    {allocations && (
                      <TableBody>
                        <FieldArray name="allocations">
                          {() => {
                            return investmentRows;
                          }}
                        </FieldArray>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12}>
                <ErrorMessage name="allocations">
                  {(msg) => (
                    <Alert severity="error" variant="outlined">
                      {msg}
                    </Alert>
                  )}
                </ErrorMessage>
              </Grid>
            </Grid>
          </Box>
          <StepButtonBar
            isSubmitting={isSubmitting}
            submitName={submitName}
            resetName={resetName}
          />
        </Form>)
}}
    </Formik>
  );
}

export default ContributionAllocationForm;
