import { Grid, IconButton, Typography } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import React, { useState } from 'react';

export enum TaxIdFormat {
  ssn = 'SSN',
  ein = 'EIN',
}

interface ObscuredTaxIdProps {
  taxId: string;
  format: TaxIdFormat;
}

function ObscuredTaxId(props: ObscuredTaxIdProps) {
  const { format = TaxIdFormat.ssn, taxId = '' } = props;
  const [isVisible, setIsVisible] = useState(false as boolean);

  // Map lookup for the mask based on the format of tax id
  const obscuredStrings = {
    [TaxIdFormat.ein]: `**-***${taxId.substring(
      taxId.length - 4,
      taxId.length
    )}`,
    [TaxIdFormat.ssn]: `***-**-${taxId.substring(
      taxId.length - 4,
      taxId.length
    )}`,
  };

  return (
    <>
      <Grid container spacing={0.5} wrap="nowrap" alignItems="center">
        <Grid item>
          <Typography variant="inherit">
            {isVisible ? taxId : obscuredStrings[format]}
          </Typography>
        </Grid>

        <Grid item>
          <IconButton
            aria-label="toggle ssn visibility"
            onClick={() => setIsVisible(!isVisible)}
            color="inherit"
          >
            {isVisible ? (
              <Visibility fontSize="small" />
            ) : (
              <VisibilityOff fontSize="small" />
            )}
          </IconButton>
        </Grid>
      </Grid>
    </>
  );
}

export default ObscuredTaxId;
